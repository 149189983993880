import Vue from 'vue'
import VueRouter from 'vue-router'

import HelloIndex from '@/components/HelloIndex'
import ProductTaxi from '@/components/ProductTaxi'
import ProductRun from '@/components/ProductRun'
import ProductCity from '@/components/ProductCity'
import ProductPlane from '@/components/ProductPlane'
import HelloAbout from '@/components/HelloAbout'
// import HelloNews from '@/components/HelloNews'
// import NewsDetail from '@/components/NewsDetail'
import HelloCase from '@/components/HelloCase'
import HelloClassic from '@/components/HelloClassic'
import HelloContact from '@/components/HelloContact'
import SolvePlat from '@/components/SolvePlat'
import SolveLicense from '@/components/SolveLicense'

// 内部调用了VueRouter的install方法
Vue.use(VueRouter)

const routes = [
  {
    path: '/helloIndex/', // 浏览器输入的路径
    component: HelloIndex, // 对应url地址的组件
    name:'佰诺出行-青岛科优佰诺'
    // children: [ // 一级路由下面配置children属性，实现二级路由配置
      // {
      //   path: '/films/comingsoon',
      //   component: ComingSoon,
      //   name: 'cs' // 路由起名字
      // }, {
      //   path: '/films/nowplaying',
      //   component: NowPlaying
      // },
      // {
      //   path: '/films',
      //   redirect: '/films/nowplaying'
      // }
    // ]
  },

  {
    path: '/productTaxi/',
    component: ProductTaxi,
    name:'出租车-佰诺出行-青岛科优佰诺'
  },
  {
    path: '/productRun/',
    component: ProductRun,
    name:'定制客运-佰诺出行-青岛科优佰诺'
    
  },
  {
    path: '/productCity/',
    component: ProductCity,
    name:'城际拼车-佰诺出行-青岛科优佰诺'
  },
  {
    path: '/productPlane/',
    component: ProductPlane,
    name:'接送机-佰诺出行-青岛科优佰诺'
  },
  {
    path: '/', // 访问根路径的时候，重定向到/films/nowplaying
    redirect: '/helloIndex/',
    name:'佰诺出行-青岛科优佰诺'
  },
  {
    path: '*', // 以上页面都没有匹配到，就会进来
    redirect: '/helloIndex/',
    name:'佰诺出行-青岛科优佰诺'
  },
  {
    path: '/helloAbout/',
    component: HelloAbout,
    name:'关于我们-佰诺出行-青岛科优佰诺'
  },
  // {
  //   path: '/helloNews',
  //   component: HelloNews,
  //   name:'S'
  // },
  {
    path: '/helloCase/',
    component: HelloCase,
    name:'案例-佰诺出行-青岛科优佰诺'
  },{
    path: '/helloClassic/',
    component: HelloClassic,
    name:'经典案例-佰诺出行-青岛科优佰诺'
  },{
    path: '/helloContact/',
    component: HelloContact,
    name:'联系我们-佰诺出行-青岛科优佰诺'
  },
  {
    path: '/solvePlat/',
    component: SolvePlat,
    name:'网约车平台-佰诺出行-青岛科优佰诺'
  },
  {
    path: '/solveLicense/',
    component: SolveLicense,
    name:'网约车牌照-佰诺出行-青岛科优佰诺'
  },

]
const router = new VueRouter({
  routes,
  mode: 'history'
})
export default router
