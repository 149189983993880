<template>
  <div >
    <div class="topPlane">
       <h1 style="color: #fff;width: 522px;font-size:48px;">接送机</h1>
       <h2 style="width: 522px;margin-top: 24px;color: #fff;text-align: justify;">系统提供紧密贴合接机、送机业务的预约用车能力，支持免费等待时长、司乘爽约、航班信息、动态接单、地图导航、服务考核、司乘同显、钱包提现、客服申诉、航班接送、高精定位、费用预估、安全监控、在线支付、行后评价、行程分享、在线开票等接送机场景的专有功能。系统支持接入携程、飞猪、同城等流量平台。</h2>
    </div>
    <div style="width: 100%;display: flex;flex-flow: column;">
        <div style="display: flex;flex-flow: column;width: 100%;align-items: center;margin-top: 43px;">
                 <img src="./static/head.png" alt="" style="width: 95px;height:95px;">
               <h1>司机端</h1>
        </div>
         <div style="display:flex;align-items: center; width: 100%;justify-content: center; ">
       <img :src="srcs" alt="" style="width:480px;margin-right:160px">
       <ul class="driver">
         <li @mouseover="touch(index)" v-for="item,index in lis" :key="index" :class="changeBackground == index?'active':'active2'" >
           <h2>{{item.title}}</h2>
           <h4>{{item.content}}</h4>
         </li>
       </ul>
     </div>
    </div>
     <div style="width: 100%; display: flex; flex-flow: column; ">
      <div
        style="
          display: flex;
          flex-flow: column;
          width: 100%;
          align-items: center;
          margin-top: 40px;
        "
      >
        <img src="./static/head2.png" alt="" style="width: 95px; height: 95px" />
        <h1>乘客端</h1>
      </div>
     <div style="display:flex;align-items: center; width: 100%;justify-content: center; ">
      
       <ul class="driver2">
         <li @mouseover="touch2(index)" v-for="item,index in lis2" :key="index" :class="changeBackground2 == index?'right':'right2'" >
           <h2>{{item.title}}</h2>
           <h4>{{item.content}}</h4>
         </li>
       </ul>
        <img :src="srcs2" alt="" style="width:480px;margin-left:160px">
     </div>
    </div>
  </div>
</template>

<script>
import srcs from './static/taxiStart31.png'
import srcs2 from './static/taxiStart32.png'
import srcs3 from './static/taxiStart33.png'
import srcs4 from './static/taxiStart34.png'
import srcs5 from './static/taxiStart35.png'
import srcs6 from './static/taxiStart36.png'
import srcs7 from './static/taxiStart37.png'
import srcs8 from './static/taxiStart38.png'
import srcs9 from './static/taxiStart39.png'
import srcs10 from './static/taxiStart40.png'
export default {
  data() {
    return {
      srcs:srcs,
      srcs2:srcs6,
      changeBackground: 0,
      changeBackground2: 0,
      lis:[{
         title:'开始接单',
         content:'点击出车，开始接单，人工智能调度'
      },{
         title:'去接乘客',
         content:'支持一键导航，线路规划'
      },{
         title:'待服务订单',
         content:'可查看待服务订单信息，接机、送机'
      },{
         title:'历史订单',
         content:'历史订单查询、乘客信息、航班信息、起始城市、接送机价格等'
      },{
         title:'我的账户',
         content:'个人信息、账户余额、可提现金额、冻结金额等'
      },

      ],
       lis2:[{
         title:'携程接送机',
         content:'支持携程、飞猪、高德、同程、去哪儿等平台订单对接'
      },{
         title:'飞猪接送机',
         content:'支持携程、飞猪、高德、同程、去哪儿等平台订单对接'
      },{
         title:'高德接送机',
         content:'支持携程、飞猪、高德、同程、去哪儿等平台订单对接'
      },{
         title:'同程接送机',
         content:'支持携程、飞猪、高德、同程、去哪儿等平台订单对接'
      },{
         title:'去哪儿接送机',
         content:'支持携程、飞猪、高德、同程、去哪儿等平台订单对接'
      },

      ]
    };
  },
  methods: {
  touch(index){
    this.changeBackground = index
    // console.log(this.changeBackground)
    if(index == 0){
      this.srcs = srcs
    }else if(index == 1){
      this.srcs = srcs2
    }else if(index == 2){
      this.srcs = srcs3
    }else if(index == 3){
      this.srcs = srcs4
    }else {
      this.srcs = srcs5
    }
  },
   touch2(index){
    this.changeBackground2 = index
    // console.log(this.changeBackground)
    if(index == 0){
      this.srcs2 = srcs6
    }else if(index == 1){
      this.srcs2 = srcs7
    }else if(index == 2){
      this.srcs2 = srcs8
    }else if(index == 3){
      this.srcs2 = srcs9
    }else {
      this.srcs2 = srcs10
    }
  }
  },
};
</script>

<style>
        .topPlane{
            background-image: url('./static/plane.png');
            background-repeat: no-repeat;
             height: 35vw;
        background-size: 100% 100%;
        min-height: 500px;
        color: #fff;
        display: flex;
        flex-flow: column;
     justify-content: center;
        padding-left: 12.5%;
        }
        h3,h5{
          text-align: left;
        }
        .driver{
           list-style: none;
        }
        .driver li{
           height: 104px;
           display: flex;
           flex-flow: column;
          justify-content: center;
          padding-left: 38px;
        }
       
         .driver2 li{
           height: 104px;
           display: flex;
           flex-flow: column;
          justify-content: center;
          padding-right: 38px;
        }
      .active{
        border-left: 8px solid #18C47C;
      }
       .active2{
        border-left: 8px solid #ECEDF1;
        opacity: 0.5;
      }
      .right{
        border-right: 8px solid #18C47C;
      }
       .right2{
        border-right: 8px solid #ECEDF1;
        opacity: 0.5;
      }
</style>