<template>
  <div>
    <div class="out">
      <h1 style="color: #fff">佰诺出行，出租车+互联网</h1>
      <h1 style="color: #fff">一款针对出租车叫车的智能管理平台</h1>
    </div>
    <div  class="chicken">
      <div class="case" >
        <div v-for="item,index in lis" class="square" :key="index">
            <img :src="item.img" alt="" class="case1" >
            <h1 class="case2" >{{item.title}}</h1>
            <h3 style="opacity:0.55;">{{item.content}}</h3>
        </div>
      </div>
      <!-- <h1 class="case3" >全国已开通服务城市</h1>
      <span class="case4" ></span>
      <div class="case5" >
        <div class="province" >
          <div class="case6" ></div>
          <h2 class="case7" >运营省份</h2>
        </div>
        <div class="province1" >
         <div class="case8" ></div>
          <h2 class="case9" >未运营省份</h2>
        </div>
      </div> -->
     
      <!-- <img src="./static/area.png" alt="" class="case10" /> -->
    </div>
    <div class="case11" >
      <h1 class="case12" >合作企业</h1>
      <div class="case13" ></div>
      <div v-for="item,index in lis2" :key="index" class="case14" >
        <img :src="i.img" alt="" v-for="i,index in lis2[index].img" :key="index" class="case15" >
      </div>
    </div>
  </div>
</template>

<script>
import img from './static/province.png'
import img1 from './static/heart.png'
import img2 from './static/user.png'
import logo1 from './static/logo (2).jpg'
import logo2 from './static/logo (4).png'
import logo3 from './static/logo(5).png'
import logo4 from './static/logo (6).png'
import logo5 from './static/logo (7).png'
import logo6 from './static/logo (8).png'
import logo7 from './static/logo (9).png'
import logo8 from './static/logo (20).png'
import logo9 from './static/logo (11).png'
import logo11 from './static/logo (14).png'
import logo13 from './static/logo (16).png'
import logo14 from './static/logo (18).png'
import logo15 from './static/logo (13).png'
import logo16 from './static/logo (13).png'
import logo18 from './static/logo (23).png'
import logo19 from './static/logo (24).png'
import logo20 from './static/logo (25).png'
import logo21 from './static/logo (26).png'
import logo22 from './static/logo (27).png'
import logo23 from './static/logo (28).png'
import logo24 from './static/logo (29).png'
import logo26 from './static/logo (31).png'
import logo27 from './static/logo (2).png'
import logo28 from './static/logo(1).png'
import logo29 from './static/12x.png'
import logo30 from './static/logo (4).png'
import logo31 from './static/logo (5).png'
import logo32 from './static/logo (7).png'
import logo33 from './static/logo (9).png'
import logo35 from './static/logo (11).png'
import logo37 from './static/2.jpg'
import logo38 from './static/20.png'
import logo39 from './static/4.png'
import logo40 from './static/5.png'
import logo41 from './static/6.png'
import logo42 from './static/10.jpg'
import logo43 from './static/11.png'
import logo44 from './static/12.jpg'
import logo45 from './static/13.jpg'
import logo46 from './static/14.png'
import logo47 from './static/15.jpg'
import logo48 from './static/16.jpg'
import logo49 from './static/17.png'
import logo50 from './static/18.jpg'
import logo51 from './static/19.png'
import logo52 from './static/3.png'
import logo53 from './static/21.jpg'
import logo54 from './static/22.jpg'
import logo55 from './static/23.png'
import logo56 from './static/24.jpg'
import logo57 from './static/25.png'
import logo58 from './static/26.png'
import logo59 from './static/27.jpg'
import logo60 from './static/28.png'
import logo61 from './static/29.jpg'
import logo62 from './static/30.jpg'
import logo63 from './static/32.jpg'
import logo64 from './static/33.jpg'
import logo65 from './static/34.png'
import logo66 from './static/35.jpg'
import logo67 from './static/36.jpg'
import logo68 from './static/37.jpg'
import logo69 from './static/38.jpg'
import logo70 from './static/39.jpg'
import logo71 from './static/40.png'
import logo72 from './static/41.png'
import logo73 from './static/42.png'
import logo74 from './static/43.png'
import logo75 from './static/44.jpg'
import logo76 from './static/45.jpg'
import logo77 from './static/46.png'
import logo78 from './static/47.jpg'
import logo79 from './static/48.png'
import logo80 from './static/49.jpg'
import logo81 from './static/50.jpg'
import logo82 from './static/51.jpg'
import logo83 from './static/52.jpg'
import logo84 from './static/53.jpg'
import logo85 from './static/54.jpg'
import logo86 from './static/55.jpg'
import logo87 from './static/56.jpg'
import logo88 from './static/57.jpg'
import logo89 from './static/60.jpg'
import logo90 from './static/62.jpg'
import logo91 from './static/63.jpg'
import logo92 from './static/67.png'
import logo93 from './static/68.png'
import logo94 from './static/logo (24).jpg'
import logo95 from './static/70.png'
import logo96 from './static/71.jpg'
import logo97 from './static/72.jpg'
import logo98 from './static/73.png'
import logo99 from './static/75.png'
import logo101 from './static/77.jpg'
import logo102 from './static/78.png'
import logo103 from './static/80.png'
export default {
  data() {
    return {
      lis:[{
        img:img,
        title:'23',
        content:'运营省份'
      },{
        img:img1,
        title:'300+',
        content:'运营城市'
      },{
        img:img2,
        title:'1000万+',
        content:'平台用户'
      }],
       lis2:[{
        img:[{
          img:logo29
        },{
          img:logo37
        },{
          img:logo38
        },{
          img:logo39
        },{
          img:logo40
        },{
          img:logo41
        },{
          img:logo22
        },{
          img:logo1
        }]
      },{
         img:[{
          img:logo3
        },{
          img:logo42
        },{
          img:logo43
        },{
          img:logo44
        },{
          img:logo45
        },{
          img:logo46
        },{
          img:logo47
        },{
          img:logo48
        }]
      },{
         img:[{
          img:logo49
        },{
          img:logo50
        },{
          img:logo51
        },{
          img:logo52
        },{
          img:logo53
        },{
          img:logo54
        },{
          img:logo55
        },{
          img:logo56
        }]
      },{
         img:[{
          img:logo57
        },{
          img:logo58
        },{
          img:logo59
        },{
          img:logo60
        },{
          img:logo61
        },{
          img:logo62
        },{
          img:logo51
        },{
          img:logo63
        }]
      },{
         img:[{
          img:logo64
        },{
          img:logo65
        },{
          img:logo66
        },{
          img:logo67
        },{
          img:logo68
        },{
          img:logo69
        },{
          img:logo70
        },{
          img:logo71
        }]
      },{
         img:[{
          img:logo72
        },{
          img:logo73
        },{
          img:logo74
        },{
          img:logo75
        },{
          img:logo76
        },{
          img:logo77
        },{
          img:logo78
        },{
          img:logo79
        }]
      },{
         img:[{
          img:logo80
        },{
          img:logo81
        },{
          img:logo82
        },{
          img:logo83
        },{
          img:logo84
        },{
          img:logo85
        },{
          img:logo86
        },{
          img:logo87
        }]
      },{
         img:[{
          img:logo88
        },{
          img:logo74
        },{
          img:logo30
        },{
          img:logo89
        },{
          img:logo23
        },{
          img:logo90
        },{
          img:logo91
        },{
          img:logo35
        }]
      },{
         img:[{
          img:logo7
        },{
          img:logo5
        },{
          img:logo92
        },{
          img:logo93
        },{
          img:logo94
        },{
          img:logo95
        },{
          img:logo96
        },{
          img:logo97
        }]
      },{
         img:[{
          img:logo98
        },{
          img:logo11
        },{
          img:logo99
        },{
          img:logo8
        },{
          img:logo101
        },{
          img:logo102
        },{
          img:logo27
        },{
          img:logo103
        }]
      }],
    };
  },
 
  methods: {
    
  },
};
</script>

<style>
.out {
  background-image: url("./static/banner2x(2).png");
  background-repeat: no-repeat;
  height: 34vw;
  min-height: 500px;
  background-size: 100% 100%;
  color: #fff;
  padding-top: 80px;
  text-align: center;
}
.chicken {
  /* background-image: url("./static/area.png");
  background-repeat: no-repeat; */
 /* height: 1145px; */
  /* height: 640px; */
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0 12.5%;
  /* background-size: 870px 744px; */
}
.square{
/* width: 464px; */
height: 280px;
flex: 1;
background: #FFFFFF;
box-shadow: 0px 4px 20px 0px rgba(214, 214, 214, 0.5);
margin-right:24px;
display: flex;
flex-flow: column;
justify-content: center;
align-items: center;
}
.a-radio .ant-radio-inner::after{
  background-color: #fff;
}
.province{
  display: flex;
  align-items: center;
}
.province1{
  display: flex;
  align-items: center;
  margin-left:64px;
}
.case{
  display:flex;
  margin-top:-120px;
  width:100%;
}
.case1{
  width:120px;
  height:120px;
}
.case2{
  margin-top:8px;
  font-size:48px;
}
.case3{
  margin:80px 0 32px;
}
.case4{
  width: 60px; 
  height: 4px; 
  background: #18c47c;
}
.case5{
  display:flex;
  align-items:center;
  margin-top:48px;
}
.case6{
  width: 24px;
  height: 24px;
  background: #18C47C;
  box-shadow: 0px 0px 11px 0px #1EF39A;
  border-radius:50%;
}
.case7{
  margin-bottom:0;
  margin-left:16px;
}
.case8{
  width: 24px;
  height: 24px;
  background: #E3E3E3;
  box-shadow: 0px 0px 11px 0px #D1D1D1;
  border-radius:50%;
}
.case9{
  margin-bottom:0;
  margin-left:16px;
}
.case10{
   width: 870px;
   height: 744px;
}
.case11{
  /* height: 1406px; */
  background: #1A2C4D;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding:0 12.5%;
}
.case12{
 margin:80px 0 32px;
 color:#fff;
}
.case13{
  width: 60px;
  height: 4px;
  background: #18C47C;
}
.case14{
 margin-top:40px;
 display:flex;
 justify-content: space-between;
 width:100%;
}
.case15{
  width: 120px;
  height: 120px;
  margin:0 0 40px 0;
  border-radius: 40px;
}
</style>