<template>
  <div>
    <div class="aboutUs">
      <h1 style="color: #fff">关于我们</h1>
      <h4 style="color: #fff">以客户为中心，诚信做事，踏实做人</h4>
    </div>
    <div class="about" >
      <div class="about1" >
        <h1 >公司简介</h1>
        <div class="about2" >
        <p style=" opacity: 0.85;text-align: justify;">青岛科优佰诺网络科技有限公司成立于2015年1月，是一家专业为巡游出租企业提供叫车平台解决方案的网络科技公司，公司拥有资深的软硬件研发工程师队伍和完善的售后服务团队，致力于为出租车企业提供一站式全场景的技术及运营服务，让合作伙伴更省钱、更省时、更省事，目前全国已加盟300+城市，业务覆盖全国23个省份。</p>
        <p style=" opacity: 0.85;text-align: justify;">我们奉行"以客户为中心，诚信做事，踏实做人"的服务理念。竭诚为客户提供最高效的出行解决方案、最高性价比的出行产品、最优质的售后服务。</p>
        <p style=" opacity: 0.85;text-align: justify;">“佰诺出行”是科优佰诺自主研发的一款针对出租车叫车的智能管理平台，目前全国已加盟300+城市，业务覆盖全国23个省份，用户关注量达到1000余万，月订单量超820万单，完单700万单，完单率86%。整个叫车过程无需客服人员干涉，智能派单，24小时运行，可以为公司节省大量的人力成本，减少出租车空载里程，防止当地黑出租运营，保护营运市场，增加出租车司机收入，也节省了乘客的叫车等待时间。方便乘客便捷出行，便于公司车辆管控。</p>
      </div>
      </div>
      <h1 >我们的优势</h1>
      <span class="about3"></span>
    </div>
    <div class="about4" >
        <div class="about5" >
          <div class="about6" >
            <img src="./static/951282x.png" alt="" class="about7" >
            <h2 class="about8" >与95128进行了对接</h2>
          </div>
          <div  class="about6"  >
            <img src="./static/taxi2.png" alt="" class="about7" >
            <h2 class="about8" >专业的技术和售后服务团队</h2>
          </div>
          <div>
            <img src="./static/order.png" alt="" class="about7" >
            <h2  class="about8">独立定制的叫车平台</h2>
          </div>
        </div>
        <p class="about9" >为切实解决老年人运用智能技术困难，优化完善老年人打车出行服务，便利老年人日常交通出行，“佰诺出行”针对老年人以及对智能机操作不熟练的人特意推出了“电话叫车”、“一键叫车”和“微信语音”叫车方式，让叫车变得非常简单，只需要记住一个电话号码、点击一键叫车或者打开微信发送一条语音说出当前位置就可以叫车。“佰诺出行”的叫车方式多样化，使用年龄跨度更大，让叫车变得更简单。</p>
        <p class="about9" >为贯彻落实《交通运输部办公厅关于印发2021年便利老年人打车出行等5件更贴近民生实事工作方案的通知》(交办运函〔2021]446号）等文件要求,切实做好便利老年人打车出行服务，“佰诺出行”率先在山东省以下地市与全国巡游出租汽车叫车热线95128进行了对接：东营市、泰安市、菏泽市、济宁市、日照市、滨州市、淄博市、聊城市、威海市、枣庄市已对接完成并运营。未来“佰诺出行”将实现全省联网，不管用户关注的是哪个地市的叫车平台都可以在任意地市呼叫本地的出租车，实现全省互联。除山东省外，“佰诺出行”在安徽全省、辽宁省、黑龙江省、吉林省、宁夏、甘肃、四川、贵州等省份共计50余地级市与全国巡游出租汽车叫车热线95128进行了对接。</p>
        <p class="about9" >“佰诺出行”都是和当地的出租车企业、交通部门合作，运营车辆都是合法正规的出租车，在出行安全方面更有保障，也得到了当地政府部门的大力支持，让乘客出行感觉到更加的放心。</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.aboutUs {
  background-image: url("./static/banner2x(4).png");
  background-repeat: no-repeat;
  height: 360px;
  width: 100%;
  background-size: 100% 100%;
  color: #fff;
  text-align: center;
  padding-top: 138px;
}
.about{
 /* height: 597px; */
 display:flex;
 align-items: center;
 flex-flow: column;
 margin-bottom: 40px;
}
.about1{
  /* height:456px; */
  box-shadow: 0px 4px 20px 0px rgba(214, 214, 214, 0.5);
  padding:40px 52px 52px;
  margin:-65px 12.5% 80px;
  background-color: #fff;
  text-align:left;
}
.about2{
  margin-top:52px;
  text-align:left;
  font-size: 18px;
}
.about3{
  width: 60px;
  height: 4px;
  background: #18C47C;
  margin-top:32px;
}
.about4{
  /* height: 680px; */
  background: #EFFAF5;
  padding:80px 12.5%;
}
.about5{
  display:flex;
  align-items: center;
  justify-content: center;
  margin-bottom:80px;
}
.about6{
  margin-right:180px;
  text-align: center;
}
.about7{
  width:160px;
  height:160px;
}
.about8{
   margin-top:24px;
}
.about9{
   opacity:0.85;
   font-size:18px;
   text-align:left;
   text-align: justify;
}
</style>