<template>
  <div>
    <div class="topCity">
       <h1 style="color:#FFFFFF;width: 522px; font-size:48px;">城际拼车</h1>
       <h2 style="width: 522px;margin-top: 24px;color:#FFFFFF;text-align: justify;">提供「从端到端」一体化的城际业务解决方案，支持打电话、微信公众号、微信小程序、等多种下单方式，以及基于线路的管理、运营能力，帮您解决从低成本获客到精细化运营中的各类问题。乘客每次叫车，优先派送给推荐司机，点对点服务、固定价格、随时发车、正规合法车辆，智能匹配订单、无需人工调度、预约抢单、抢单大厅、电子围栏、上报空位、导航接送等功能。</h2>
    </div>
    <div style="width: 100%;display: flex;flex-flow: column;">
        <div style="display: flex;flex-flow: column;width: 100%;align-items: center;margin-top: 43px;">
                 <img src="./static/head.png" alt="" style="width: 95px;height:95px;">
               <h1>司机端</h1>
        </div>
         <div style="display:flex;align-items: center; width: 100%;justify-content: center; ">
       <img :src="srcs" alt="" style="width:480px;margin-right:160px">
       <ul class="driver">
         <li @mouseover="touch(index)" v-for="item,index in lis" :key="index" :class="changeBackground == index?'active':'active2'" >
           <h2>{{item.title}}</h2>
           <h4>{{item.content}}</h4>
         </li>
       </ul>
     </div>
    </div>
     <div style="width: 100%; display: flex; flex-flow: column; ">
      <div
        style="
          display: flex;
          flex-flow: column;
          width: 100%;
          align-items: center;
          margin-top: 40px;
        "
      >
        <img src="./static/head2.png" alt="" style="width: 95px; height: 95px" />
        <h1>乘客端</h1>
      </div>
     <div style="display:flex;align-items: center; width: 100%;justify-content: center; ">
      
       <ul class="driver2">
         <li @mouseover="touch2(index)" v-for="item,index in lis2" :key="index" :class="changeBackground2 == index?'right':'right2'" >
           <h2>{{item.title}}</h2>
           <h4>{{item.content}}</h4>
         </li>
       </ul>
        <img :src="srcs2" alt="" style="width:480px;margin-left:160px">
     </div>
    </div>
  </div>
</template>

<script>
import srcs from './static/taxiStart21.png'
import srcs2 from './static/taxiStart22.png'
import srcs3 from './static/taxiStart23.png'
import srcs4 from './static/taxiStart24.png'
import srcs5 from './static/taxiStart25.png'
import srcs6 from './static/taxiStart26.png'
import srcs7 from './static/taxiStart27.png'
import srcs8 from './static/taxiStart28.png'
import srcs9 from './static/taxiStart29.png'
import srcs10 from './static/taxiStart30.png'
export default {
  data() {
    return {
      srcs:srcs,
      srcs2:srcs6,
      changeBackground: 0,
      changeBackground2: 0,
      lis:[{
         title:'开始接单',
         content:'点击出车，开始接单，支持抢单、指派、抢派3种派单模式'
      },{
         title:'司机接单',
         content:'显示起始点位置、距离乘客米数、2种应答模式'
      },{
         title:'去接乘客',
         content:'内置导航接送乘客，乘客位置可视化，乘客人数、起点位置一目了然'
      },{
         title:'空座上报',
         content:'司机端可随时上报车辆空座数，方便系统指派订单'
      },{
         title:'接单设置',
         content:'个性化设置，司机可根据自己习惯设置相应参数'
      },

      ],
       lis2:[{
         title:'拼车首页',
         content:'支持微信公众号、微信小程序、电话等叫车方式'
      },{
         title:'选择上车地点',
         content:'系统可设置运营区域电子围栏，在服务围栏内选择上车地点'
      },{
         title:'选择下车地点',
         content:'系统可设置运营区域电子围栏，在服务围栏内选择下车地点'
      },{
         title:'我的行程',
         content:'历史行程记录、点击可查看行程详情'
      },{
         title:'个人中心',
         content:'我的行程、我的积分、更改手机、在线客服等'
      },

      ]
    };
  },
  methods: {
  touch(index){
    this.changeBackground = index
    // console.log(this.changeBackground)
    if(index == 0){
      this.srcs = srcs
    }else if(index == 1){
      this.srcs = srcs2
    }else if(index == 2){
      this.srcs = srcs3
    }else if(index == 3){
      this.srcs = srcs4
    }else {
      this.srcs = srcs5
    }
  },
   touch2(index){
    this.changeBackground2 = index
    // console.log(this.changeBackground)
    if(index == 0){
      this.srcs2 = srcs6
    }else if(index == 1){
      this.srcs2 = srcs7
    }else if(index == 2){
      this.srcs2 = srcs8
    }else if(index == 3){
      this.srcs2 = srcs9
    }else {
      this.srcs2 = srcs10
    }
  }
  },
};
</script>

<style>
        .topCity{
            background-image: url('./static/city.png');
            background-repeat: no-repeat;
               height: 35vw;
               min-height: 500px;
        background-size: 100% 100%;
        color: #fff;
        display: flex;
        flex-flow: column;
     justify-content: center;
        padding-left: 12.5%;
        }
        h3,h5{
          text-align: left;
        }
        .driver{
           list-style: none;
        }
        .driver li{
           height: 104px;
           display: flex;
           flex-flow: column;
          justify-content: center;
          padding-left: 38px;
        }
       
         .driver2 li{
           height: 104px;
           display: flex;
           flex-flow: column;
          justify-content: center;
          padding-right: 38px;
        }
      .active{
        border-left: 8px solid #18C47C;
      }
       .active2{
        border-left: 8px solid #ECEDF1;
        opacity: 0.5;
      }
      .right{
        border-right: 8px solid #18C47C;
      }
       .right2{
        border-right: 8px solid #ECEDF1;
        opacity: 0.5;
      }
</style>