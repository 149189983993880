<template>
  <div>
    <div class="dongying">
      <div style="max-width: 520px;min-width: 450px;">
        <div style="display: flex; align-items: center">
          <img
            src="./static/12x.png"
            alt=""
            class="classic"/>
          <h1 class="classic1" >东营出行</h1>
        </div>
        <p class="classic2" >
          ＂东营出行＂叫车软件自2017年9月15日在东营运营以来，得到了广大乘客和司机的大力支持和推广，现乘客关注人数已超100万+，日订单量2.5万以上，早晚高峰期间每秒钟就可产生3-5个订单，每秒钟就会帮助3-5个乘客叫到车，每天同时在线司机2000辆车以上。
        </p>
      </div>
      <div class="classic3" >
      <img
        src="./static/right.jpg"
        alt=""
        style="width: 100%; height: 23vw;min-height: 270px; "
      />
      </div>
    </div>
    <div class="classic4"
     >
      <h1 class="classic5" >订单报表柱状图统计</h1>
      <div class="classic6"></div>
      <!-- <div class="classic7"> -->
        <!-- <div class="classic8" >
          <h1 style="font-weight:bold;">新出租数字化解决方案</h1>
          <h4 style="opacity: 0.55">
            整个叫车过程无需客服人员干涉，智能派单，24小时运行，可以为公司节省大量的人力成本，减少出租车空载里程，防止当地黑出租运营，保护营运市场，增加出租车司机收入，也节省了乘客的叫车等待时间。方便乘客便捷出行，便于公司车辆管控，系统采用GPS+北斗双模式定位，实时显示每一辆车的位置信息。
          </h4>
        </div>
        <div style="flex:1.6;"> -->
           <img
          src="./static/position(5).png"
          alt=""
          class="classic9"
          
        />
        <!-- </div> -->
       
      <!-- </div> -->
      <h1 class="classic5">日订单报表柱状图统计</h1>
      <div  class="classic6"></div>
      <!-- <div class="classic10"> -->
        <!-- <div style="flex:1.6;"> -->
           <img
             src="./static/position(6).png"
             alt=""
             class="classic11"
             />
        <!-- </div> -->
        <!-- <div class="classic8">
          <h1 style="font-weight:bold;">新出租数字化解决方案</h1>
          <h4 style="opacity: 0.55">
            整个叫车过程无需客服人员干涉，智能派单，24小时运行，可以为公司节省大量的人力成本，减少出租车空载里程，防止当地黑出租运营，保护营运市场，增加出租车司机收入，也节省了乘客的叫车等待时间。方便乘客便捷出行，便于公司车辆管控，系统采用GPS+北斗双模式定位，实时显示每一辆车的位置信息。
          </h4>
        </div> -->
      <!-- </div> -->
      <h1 class="classic5">系统后台数据展示</h1>
      <div class="classic6"></div>
    </div>
    <div class="four3">
        <h1 style="color:#fff;text-align:left;">新出租数字化解决方案</h1>
        <p class="classic12" >
          整个叫车过程无需客服人员干涉，智能派单，24小时运行，可以为公司节省大量的人力成本，减少出租车空载里程，防止当地黑出租运营，保护营运市场，增加出租车司机收入，也节省了乘客的叫车等待时间。方便乘客便捷出行，便于公司车辆管控，系统采用GPS+北斗双模式定位，实时显示每一辆车的位置信息。支持抢单、指派、抢派3种派单模式，线下/线上支付、双向互评、查看司机位置、地图导航、热力图等功能。
        </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>

.dongying {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 500px;
  /* width: 1920px; */
  padding: 0 12.5%;
  height: 35vw;
  background: linear-gradient(270deg, #45c6ff 0%, #1b61f3 100%);
}
.four3{
     background-image: url("./static/BG2x(1).png");
  background-repeat: no-repeat;
  min-height: 350px;
  height: 25vw;
  width: 100%;
  background-size: 100% 100%;
  color: #fff;
  padding: 10% 0 0 12%;
}
.classic{
    width: 80px; 
    height: 80px; 
    margin-right: 32px;
          
}
.classic1{
  color: #fff;
  font-size:48px;
  letter-spacing: 10px;
}
.classic2{
 color: #fff; 
 margin-top: 24px;
 font-size:24px;
 text-align: justify;
}
.classic3{
  height: 23vw; 
  margin-left: 90px;
  min-height: 270px;
  /* flex:1.3; */
}
.classic4{
        /* height: 1412px; */
        padding: 0 12.5% 3%;
        display: flex;
        align-items: center;
        flex-flow: column;
}
.classic5{
  margin: 80px 0 32px;
}
.classic6{
   width: 60px; 
   height: 4px; 
   background: #18c47c;

}
.classic7{
          display: flex;
          align-items: center;
          margin-top: 118px;
          width:100%;
}
.classic8{
  flex:1;
  margin-right:134px;
}
.classic9{
  /* height: 279px; */
  width:100%;
}
.classic10{
          display: flex;
          justify-content:  space-between;
          align-items: center;
          margin-top: 80px;
          width:100%;
}
.classic11{
   /* height: 354px; */
   width:100%;
}
.classic12{
 width: 478px; 
 margin-top: 20px;
}
</style>