<template>
  <div>
    <div class="contactUs">
      <h1 style="color: #fff;">联系我们</h1>
      <h4 style="color: #fff">以客户为中心，诚信做事，踏实做人</h4>
    </div>
   <div style="display:flex;justify-content: center;align-items: center;width:100%;padding:0 12.5%;">
       <div class="small" >
           <img src="./static/address.png" alt="" class="contact" >
           <h3 class="contact1" >公司地址</h3>
           <h5 class="contact2" >山东省青岛市市北区中央商务区卓越世纪中心3座1316</h5>
       </div>
       <div class="small" style="margin:24px;">
           <img src="./static/phone.png" alt="" class="contact" >
           <h3 class="contact1" >联系电话</h3>
           <h5 class="contact2" >手机：15954211165</h5>
           <h5 class="contact2">座机：0532-88180018</h5>
       </div>
       <div class="small">
           <img src="./static/e-mail.png" alt="" class="contact" >
           <h3 class="contact1" >电子邮箱</h3>
           <h5 class="contact2" >keubano@foxmail.com</h5>
       </div>
   </div>
      <div class="contact3" >
         <img src="./static/point1.png" alt="" style="width:100%;">
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        
    };
  },
  methods: {
    
  },
};
</script>

<style>
.contactUs {
  background-image: url("./static/banner222x.png");
  background-repeat: no-repeat;
  height: 360px;
  width: 100%;
  background-size: 100% 100%;
padding-top: 138px;
text-align: center;
}
.small{
     /* width: 464px; */
     flex: 1;
     height: 400px;
     background: #FFFFFF;
     box-shadow: 0px 4px 20px 0px rgba(214, 214, 214, 0.5);
     display:flex;
     /* justify-content: center; */
     align-items: center;
     flex-flow: column;
     margin:80px  0;
     padding: 70px 20px 0;
}

.contact{
  width: 80px;
  height: 80px;
}
.contact1{
margin-top:48px;
font-weight: 600;
}
.contact2{
margin-top:24px;
font-size: 16px;
}
.contact3{
 /* height: 640px; */
 width: 100%;
 padding: 0 12.5%;
 
}
.contact4{
  width: 100%;
  height: 640px;
}
</style>