<template>
  <div>
    <div class="topRun">
       <h1 style="color:#FFFFFF;width: 522px;font-size:48px; ">定制客运</h1>
       <h2 style="width: 522px;margin-top: 24px;color:#FFFFFF;text-align: justify;">较传统“站到站”的客运组织方式，定制客运是以旅客出行需求为导向，以“门到门”，“点到点”便捷服务为核心，以7座、9座商务车为主要车型，旅客通过互联网平台进行在线预订、在线支付、在线评价，从而为广大旅客提供更安全、更便捷、更高效、更舒适的一种出行服务方式。</h2>
    </div>
    <div style="width: 100%;display: flex;flex-flow: column;">
        <div style="display: flex;flex-flow: column;width: 100%;align-items: center;margin-top: 43px;">
                 <img src="./static/head.png" alt="" style="width: 95px;height:95px;">
               <h1>司机端</h1>
        </div>
        <div style="display:flex;align-items: center; width: 100%;justify-content: center; ">
       <img :src="srcs" alt="" style="width:480px;margin-right:160px">
       <ul class="driver">
         <li @mouseover="touch(index)" v-for="item,index in lis" :key="index" :class="changeBackground == index?'active':'active2'" >
           <h2>{{item.title}}</h2>
           <h4>{{item.content}}</h4>
         </li>
       </ul>
     </div>
    </div>
    <div style="width: 100%; display: flex; flex-flow: column; ">
      <div
        style="
          display: flex;
          flex-flow: column;
          width: 100%;
          align-items: center;
          margin-top: 40px;
        "
      >
        <img src="./static/head2.png" alt="" style="width: 95px; height: 95px" />
        <h1>乘客端</h1>
      </div>
     <div style="display:flex;align-items: center; width: 100%;justify-content: center; ">
      
       <ul class="driver2">
         <li @mouseover="touch2(index)" v-for="item,index in lis2" :key="index" :class="changeBackground2 == index?'right':'right2'" >
           <h2>{{item.title}}</h2>
           <h4>{{item.content}}</h4>
         </li>
       </ul>
        <img :src="srcs2" alt="" style="width:480px;margin-left:160px">
     </div>
    </div>
  </div>
</template>

<script>
import srcs from './static/taxiStart11.png'
import srcs2 from './static/taxiStart12.png'
import srcs3 from './static/taxiStart14.png'
import srcs4 from './static/taxiStart15.png'
import srcs5 from './static/taxiStart13.png'
import srcs6 from './static/taxiStart16.png'
import srcs7 from './static/taxiStart17.png'
import srcs8 from './static/taxiStart18.png'
import srcs9 from './static/taxiStart19.png'
import srcs10 from './static/taxiStart20.png'
export default {
  data() {
    return {
      srcs:srcs,
      srcs2:srcs6,
      changeBackground: 0,
      changeBackground2: 0,
      lis:[{
         title:'开始接单',
         content:'点击出车，开始接单，人工智能调度'
      },{
         title:'司机接单',
         content:'系统派单后可点击订单进行相应服务、支持中途上车'
      },{
         title:'去接乘客',
         content:'电子票据、上车提供核验码即可验票'
      },{
         title:'智能规划线路',
         content:'系统智能规划最优线路，每个途经点都是一个乘客的上下车位置'
      },{
         title:'去送乘客',
         content:'一键导航、司机可根据导航接送乘客'
      },

      ],
       lis2:[{
         title:'班线首页',
         content:'支持微信公众号、微信小程序等叫车方式'
      },{
         title:'选择班线',
         content:'输入起始城市查询相应班线，选择适合自己的班线下单'
      },{
         title:'提交订单',
         content:'选择上下车地址、添加乘车人信息后，提交订单'
      },{
         title:'用车须知',
         content:'下单前提醒用户用车须知'
      },{
         title:'添加乘车人',
         content:'支持添加多个乘车人信息，在下单时选择使用'
      },

      ]
    };
  },
  methods: {
  touch(index){
    this.changeBackground = index
    // console.log(this.changeBackground)
    if(index == 0){
      this.srcs = srcs
    }else if(index == 1){
      this.srcs = srcs2
    }else if(index == 2){
      this.srcs = srcs3
    }else if(index == 3){
      this.srcs = srcs4
    }else {
      this.srcs = srcs5
    }
  },
   touch2(index){
    this.changeBackground2 = index
    // console.log(this.changeBackground)
    if(index == 0){
      this.srcs2 = srcs6
    }else if(index == 1){
      this.srcs2 = srcs7
    }else if(index == 2){
      this.srcs2 = srcs8
    }else if(index == 3){
      this.srcs2 = srcs9
    }else {
      this.srcs2 = srcs10
    }
  }
  },
};
</script>

<style>
        .topRun{
            background-image: url('./static/run.png');
            background-repeat: no-repeat;
            height: 35vw;
            min-height: 500px;
        background-size: 100% 100%;
        color: #fff;
        display: flex;
        flex-flow: column;
     justify-content: center;
        padding-left: 12.5%;
        }
                h3,h5{
          text-align: left;
        }
        .driver{
           list-style: none;
        }
        .driver li{
           height: 104px;
           display: flex;
           flex-flow: column;
          justify-content: center;
          padding-left: 38px;
        }
       
         .driver2 li{
           height: 104px;
           display: flex;
           flex-flow: column;
          justify-content: center;
          padding-right: 38px;
        }
      .active{
        border-left: 8px solid #18C47C;
      }
       .active2{
        border-left: 8px solid #ECEDF1;
        opacity: 0.5;
      }
      .right{
        border-right: 8px solid #18C47C;
      }
       .right2{
        border-right: 8px solid #ECEDF1;
        opacity: 0.5;
      }

</style>