<template>
<div>
  <a-carousel arrows class="up" autoplay :autoplay-speed="5000">
    <div
      slot="prevArrow"
      class="custom-slick-arrow"
      style="left: 10px;z-Index: 1"
    >
      <a-icon type="left-circle" />
    </div>
    <div slot="nextArrow"  class="custom-slick-arrow" style="right: 10px">
      <a-icon type="right-circle" />
    </div>
    <div class="bg1">
      <div class="topTitle">
      <p class="topTitle1">出租车电召平台</p>
      <p class="topTitle2" >全程无人工干预24小时运行</p>
      <p class="topTitle3" >一键叫车/电话叫车/微信叫车/小程序叫车/APP叫车/摇一摇叫车</p>
      <a-button class="topTitle4" type="link" shape="round"  size="small" href="/productTaxi/">了解详情<a-icon type="arrow-right" /></a-button>

      </div>
       </div>
    <div class="bg2">
      <div class="topTitle">
      <p class="topTitle1">定制客运系统</p>
      <p class="topTitle2" >班线也可像约网约车一样便捷</p>
      <p class="topTitle3" >一键呼叫/上门接送/点到点/门到门/自动规划最优线路/在线预定/在线支付/电子围栏</p>
      <a-button class="topTitle4" type="link" shape="round"  size="small" href="/productRun/">了解详情<a-icon type="arrow-right" /></a-button>
      </div>
       </div>
   <div class="bg3">
     <div class="topTitle6" >
      <p class="topTitle1" >网约车牌照申请</p>
      <ul class="application" >
        <li>120天帮你快速拿到《网络预约出租汽车经营许可证》</li>
        <li>50+城市对接成功经验</li>
        <li>成熟Saas系统、熟悉全部申请流程、速度快</li>
      </ul>
      <a-button class="topTitle4" type="link" shape="round"  size="small" href="/solveLicense/">了解详情<a-icon type="arrow-right" /></a-button>
      </div>
       </div>
  </a-carousel>
   <div class="topTitle7" >
        <h1 class="express">EXPRESS</h1>
        <div class="topTitle8" >
            <h1 style="font-size:30px;">业务介绍</h1>
            <div class="topTitle9" ></div>
            <h3 class="topTitle10" >更便捷、经济、舒适的出行服务</h3>
        </div>
    </div>
    <div style="display: flex;margin-left:12.5%;">
        <div class="mid">
            <img :src="src1" alt="" class="topTitle11"   @click="goTo(1)">
            <h4>出租车</h4>
        </div>
        <div class="mid">
            <img :src="src2" alt="" class="topTitle11"  @click="goTo(2)">
            <h4>城际拼车</h4>
        </div>
        <div class="mid">
            <img :src="src3" alt="" class="topTitle11"  @click="goTo(3)">
            <h4>定制客运</h4>
        </div>
        <div class="mid">
            <img :src="src4" alt="" class="topTitle11"  @click="goTo(4)">
            <h4>接送机</h4>
        </div>
    </div>
    <!-- <div class="four"> -->
       <a-carousel  id="midNo" ref="varousel" >
    <div v-for="item,index in lis" :key="index">
      <div class="midNo2" >
         <div style="max-width: 478px;margin-right:10px;">
            <h1 style="color:#FFFFFF">{{item.title}}</h1>
            <p style="color:#FFFFFF;margin-top: 20px;text-align: justify;">{{item.content}}</p>
            <p style="color:#FFFFFF;margin-top: 20px;text-align: justify;">{{item.content1}}</p>
            <a :href="lisHref" style="color: #FFFFFF;" @click="goToHref(index)">了解详情<a-icon type="arrow-right" /></a>
        </div> 
        <img :src="item.src5" alt="" style="width: 56%;height:100%;"> 
        </div>
    </div>
  </a-carousel>
        
    <!-- </div> -->
     <div class="map"
        style="height: 50vw;width: 100%;min-height: 700px; background-size:100% 100%;">
        <div style="height: 350px;display: flex;flex-flow: column;align-items: center;justify-content: center;">
            <h1 style=" text-shadow: 2px 2px 5px #18C47C ;font-size: 80px;color: #000000;margin-top:80px;">COVERAGE AREA</h1>
            <div
                style="display: flex;align-items: center;justify-content: center;flex-flow: column;color: #FFFFFF;margin-top: -101px;">
                <h1 style="color:#fff;">业务覆盖区域</h1>
                <div style="width: 32px;height: 4px;background-color: #18C47C;margin-top: 11px;"></div>
                <h5 style="margin-top: 24px;font-weight: normal;color:#fff;">更便捷、经济、舒适的出行服务</h5>
            </div>
            <div style="color: #FFFFFF;display: flex;margin-top: 48px;">
                <div>
                    <div style="display: flex;">
                        <h1 style="color:#fff;font-size:48px;margin-bottom:0;">23</h1>
                        <h3 style="margin:10px 0 0 16px;color:#fff;">个</h3>
                    </div>
                    <h3 style="font-weight: normal;text-align: center;color:#fff;">运营省份</h3>
                </div>
                <div style="margin-left: 199px;">
                    <div style="display: flex;">
                        <h1 style="color:#fff;font-size:48px;margin-bottom:0;">300+</h1>
                        <h4 style="margin:10px 0 0 16px;color:#fff;">座</h4>
                    </div>
                    <h3 style="font-weight: normal;text-align: center;color:#fff;">运营城市</h3>
                </div>
                <div style="margin-left: 199px;">
                    <div style="display: flex;">
                        <h1 style="color:#fff;font-size:48px;margin-bottom:0;">1000</h1>
                        <h4 style="margin:10px 0 0 16px;color:#fff;">万+</h4>
                    </div>
                    <h3 style="font-weight: normal;text-align: center;color:#fff;">平台用户</h3>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
 import taxi from './static/taxi(1).png'
 import taxi2 from './static/taxi(2).png'
 import city from './static/taxi3.png'
 import city2 from './static/city2.png'
 import plane from './static/taxi5.png'
 import run2 from './static/run2.png'
 import run from './static/taxi.png'
 import plane2 from './static/plane2.png'
 import other from './static/phone2.png'
 import other1 from './static/phone3.png'
 import other2 from './static/phone4.png'
 import other3 from './static/phone5.png'
export default {
    data(){
        return{
            src1:taxi,
            src2:city,
            src3:run,
            src4:plane,
            src5:other,
            lis:[{
              title:'新出租数字化解决方案',
              src5:other,
              content:' 整个叫车过程无需客服人员干涉，智能派单，24小时运行，可以为公司节省大量的人力成本，减少出租车空载里程，防止当地黑出租运营，保护营运市场，增加出租车司机收入，也节省了乘客的叫车等待时间。方便乘客便捷出行，便于公司车辆管控，系统采用GPS+北斗双模式定位，实时显示每一辆车的位置信息。支持抢单、指派、抢派3种派单模式，线下/线上支付、双向互评、查看司机位置、地图导航、热力图等功能。',

            },{
              title:'城际拼车数字化解决方案',
              src5:other1,
              content:' 提供「从端到端」一体化的城际业务解决方案，支持打电话、微信公众号、微信小程序、等多种下单方式，以及基于线路的管理、运营能力，帮您解决从低成本获客到精细化运营中的各类问题。乘客每次叫车，优先派送给推荐司机，点对点服务、固定价格、随时发车、正规合法车辆，智能匹配订单、无需人工调度、预约抢单、抢单大厅、电子围栏、上报空位、导航接送等功能。',

            },{
              title:'定制客运数字化解决方案',
              src5:other2,
              content:'较传统“站到站”的客运组织方式，定制客运是以旅客出行需求为导向，以“门到门”，“点到点”便捷服务为核心，以7座、9座商务车为主要车型，旅客通过互联网平台进行在线预订、在线支付、在线评价，从而为广大旅客提供更安全、更便捷、更高效、更舒适的一种出行服务方式。',
              content1:'定制客运服务作为传统客运的升级版和有益补充，是推进道路客运供给侧结构性改革和满足人民群众日益增长的美好出行需求的重要手段。希望道路客运企业积极开展形式多样的定制客运服务，促进行业转型升级，为人民群众提供更加便捷、舒适、安全的道路客运出行服务。'
            },{
              title:'接送机数字化解决方案',
              src5:other3,
              content:' 系统提供紧密贴合接机、送机业务的预约用车能力，支持免费等待时长、司乘爽约、航班信息、动态接单、地图导航、服务考核、司乘同显、钱包提现、客服申诉、航班接送、高精定位、费用预估、安全监控、在线支付、行后评价、行程分享、在线开票等接送机场景的专有功能。系统支持接入携程、飞猪、同程等流量平台。',

            }],
            lisHref:''
        }
    },
   methods: {
			logo(e) {
				console.log('怎么这么慢',e)
			},
     goTo(index) {

       this.$refs.varousel.goTo(index - 1);
       if (index == 1) {
         this.src1 = taxi
         this.src2 = city
         this.src3 = run
         this.src4 = plane
       } else if (index == 2) {
         this.src1 = taxi2
         this.src2 = city2
         this.src3 = run
         this.src4 = plane
       } else if (index == 3) {
         this.src1 = taxi2
         this.src2 = city
         this.src3 = run2
         this.src4 = plane
       } else {
         this.src1 = taxi2
         this.src2 = city
         this.src3 = run
         this.src4 = plane2
       }
     },
     goToHref(index){
      console.log("goToHref",index)
      if(index == 0){
        this.lisHref = '/productTaxi/'
      }else if (index == 1) {
        this.lisHref = '/productCity/'
      }else if (index == 2) {
        this.lisHref = '/productRun/'
      }else  {
        this.lisHref = '/productPlane/'
      }
     }
		}
};
</script>
<style scoped>
.up{
    height: 50vw;
    min-height: 600px;
width: 100%;
}
/* For demo */
.up .ant-carousel >>> .slick-slide {
  text-align: center;
  height: 1000px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}
.ant-carousel .slick-next::before {
    content: '';
}
/* 原来样式做个标记 */
/* .ant-carousel .slick-prev::before {
    content: '←';
} */
.ant-carousel .slick-prev::before {
    content: '';
}


 .ant-carousel >>> .custom-slick-arrow {
  width: 64px;
  height: 64px;
  font-size: 64px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
 .ant-carousel >>> .custom-slick-ablankrrow:before {
  display: none;
}
 .ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
  color: #18C47C;
}

 .ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
#midNo{
  height: 35vw;
width: 100%;
min-height: 400px;
background-image: url('./static/BG2x.png');
          background-repeat: no-repeat;
          width: 100%; 
          background-size:100% 100%;}
#midNo .ant-carousel >>> .slick-slide {
  text-align: center;
  height: 650px;
  line-height: 650px;
  background: #364d79;
  /* overflow: hidden; */
}
.midNo2{
  background-image: url('./static/BG2x.png');
          background-repeat: no-repeat;
          background-size:100% 100%;
          display: flex;
          justify-content: space-between; 
          padding: 0 12.5%;
          align-items: center;
}
        .express {
            text-shadow: 2px 2px 5px #D9ECE3;
            font-size: 80px;
            color: #FFFFFF;
            /* position: absolute;
            top: 1135px;
            z-index: -1; */
        }

        .mid {
            margin-right: 80px;
            text-align: center;
        }
        .map{
          background-image: url('./static/BGmap.png');
          background-repeat: no-repeat;
          color: #FFFFFF;
        }
        .bg1{
            background-image: url('./static/banner1.png');
          background-repeat: no-repeat;
          color: #FFFFFF;
          min-height: 600px;
          height:50vw;
          width: 100%; 
          background-size:100% 100%;
          padding: 0 0 0 12.5%;
         
        }
        .bg2{
            background-image: url('./static/banner2.png');
          background-repeat: no-repeat;
          color: #FFFFFF;
          min-height: 600px;
          height:50vw;
          width: 100%; 
          background-size:100% 100%;
          padding: 0 0 0 12.5%;
        }
        .bg3{
            background-image: url('./static/banner3.png');
          background-repeat: no-repeat;
          color: #FFFFFF;
          min-height: 600px;
          height:50vw;
          width: 100%; 
          background-size:100% 100%;
          padding: 0 0 0 12.5%;
        }
     .topTitle{
       width: 600px;
 display: flex;
          flex-flow: column;
          align-items: flex-start;
          position: relative;
          top: 20%;
     }
      .topTitle6{
       width: 650px;
       display: flex;
          flex-flow: column;
          align-items: flex-start;
          position: relative;
          top: 20%;
     }
     .application{
       margin-bottom:40px
     }
     .application li::marker{
            color: #18C47C ;
        }
        .application li{
          font-size: 24px;
          letter-spacing: 13px;
          width:100%;
        }
        .topTitle1{
 font-size: 48px;
 margin-bottom:10px;
 letter-spacing: 10px;
 width:100%;
        }
        .topTitle2{
font-size: 24px;
letter-spacing: 13px;
margin-bottom:40px;
width:100%;
        }

        .topTitle3{
          font-size: 18px;
          letter-spacing: 12px;
          margin-bottom:40px;
          width:100%;
        }
        .ant-btn-round.ant-btn-sm{

width: 120px;
height: 40px;
        }
        .topTitle4{
          background:#18C47C;
border-Color:#18C47C;
line-height: 38px;
        }
  .topTilte5{
    font-size: 48px;
    margin-bottom:10px;
    letter-spacing: 10px;
  }
  .topTitle7{
    height: 350px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
  .topTitle8{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin-top:-103px;
  }
  .topTitle9{
width: 32px;
height: 4px;
background-color: #18C47C;
margin-top: 11px;
  }
  .topTitle10{
margin-top: 24px;
font-size:14px;
opacity:0.55;
  }
  .topTitle11{
width: 80px;
height:80px;
  }
  .ant-btn-link{
    color: #FFFFFF;
  }
  .ant-btn-link:hover{
    color: #FFFFFF;
  }
  .ant-btn:hover{
    color: #FFFFFF;
  }
</style>
