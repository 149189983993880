<template>
  <div>
    
    <div class="topTaxi" >
      <h1 style="color:#FFFFFF;width: 522px;font-size:48px;">出租车</h1>
      <h2 style="width: 522px; margin-top: 24px;color:#FFFFFF;text-align: justify;">
        整个叫车过程无需客服人员干涉，智能派单，24小时运行，可以为公司节省大量的人力成本，减少出租车空载里程，防止当地黑出租运营，保护营运市场，增加出租车司机收入，也节省了乘客的叫车等待时间。方便乘客便捷出行，便于公司车辆管控，系统采用GPS+北斗双模式定位，实时显示每一辆车的位置信息。支持抢单、指派、抢派3种派单模式，线下/线上支付、双向互评、查看司机位置、地图导航、热力图等功能。
      </h2>
    </div>
    <div style="width: 100%; display: flex; flex-flow: column; ">
      <div
        style="
          display: flex;
          flex-flow: column;
          width: 100%;
          align-items: center;
          margin-top: 43px;
        "
      >
        <img src="./static/head.png" alt="" style="width: 95px; height: 95px" />
        <h1>司机端</h1>
      </div>
     <div style="display:flex;align-items: center; width: 100%;padding: 0 12.5%;justify-content: space-between; ">
       <img :src="srcs" alt="" style="width:480px;">
       <ul class="driver">
         <li @mouseover="touch(index)" v-for="item,index in lis" :key="index" :class="changeBackground == index?'active':'active2'" >
           <h2>{{item.title}}</h2>
           <h4>{{item.content}}</h4>
         </li>
       </ul>
     </div>
    </div>
     <div style="width: 100%; display: flex; flex-flow: column; ">
      <div
        style="
          display: flex;
          flex-flow: column;
          width: 100%;
          align-items: center;
          margin-top: 40px;
        "
      >
        <img src="./static/head2.png" alt="" style="width: 95px; height: 95px" />
        <h1>乘客端</h1>
      </div>
     <div style="display:flex;align-items: center; width: 100%;padding: 0 12.5%; justify-content: space-between; ">
      
       <ul class="driver2">
         <li @mouseover="touch2(index)" v-for="item,index in lis2" :key="index" :class="changeBackground2 == index?'right':'right2'" >
           <h2>{{item.title}}</h2>
           <h4>{{item.content}}</h4>
         </li>
       </ul>
        <img :src="srcs2" alt="" style="width:480px;">
     </div>
    </div>
    
  </div>
</template>

<script>
import srcs from './static/taxiStart.png'
import srcs2 from './static/taxiStart2.png'
import srcs3 from './static/taxiStart3.png'
import srcs4 from './static/taxiStart4.png'
import srcs5 from './static/taxiStart5.png'
import srcs6 from './static/taxiStart6.png'
import srcs7 from './static/taxiStart7.png'
import srcs8 from './static/taxiStart8.png'
import srcs9 from './static/taxiStart9.png'
import srcs10 from './static/taxiStart10.png'

export default {
  data() {
    return {
      srcs:srcs,
      srcs2:srcs6,
      changeBackground: 0,
      changeBackground2: 0,
      lis:[{
         title:'开始接单',
         content:'点击出车，开始接单，支持抢单、指派、抢派3种派单模式'
      },{
         title:'司机接单',
         content:'卡片式订单列表，可左右滑动查看订单，点击接单按钮接单'
      },{
         title:'去接乘客',
         content:'内置导航接送乘客，乘客位置可视化、司乘互评、拨打电话、新老乘客一眼便知'
      },{
         title:'去送乘客',
         content:'内置导航接送乘客，司乘互评、拨打电话，滑动按钮完成订单'
      },{
         title:'我的排行',
         content:'可视化图表、各种订单类型数据统计'
      },

      ],
       lis2:[{
         title:'实时约车',
         content:'实时用车、预约用车、周围司机展示、位置搜索、摇一摇叫车、助老模式'
      },{
         title:'发布行程',
         content:'一键叫车、帮忙叫车、行程预估、线路规划、补贴司机空驶费'
      },{
         title:'等待接驾',
         content:'就近派单、自动匹配最新司机、取消行程'
      },{
         title:'送驾行程',
         content:'线路实时导航、司机信息展示、紧急求助、行程分享'
      },{
         title:'行程结束',
         content:'现金支付、在线支付多种支付方式，服务评价'
      },

      ]
    };
  },
  methods: {
  touch(index){
    this.changeBackground = index
    if(index == 0){
         this.srcs = srcs
    }else if(index == 1){
      this.srcs = srcs2
    }else if(index == 2){
      this.srcs = srcs3
    }else if(index == 3){
      this.srcs = srcs4
    }else {
      this.srcs = srcs5
    }
  },
   touch2(index){
    this.changeBackground2 = index
    // console.log(this.changeBackground)
    if(index == 0){
         this.srcs2 = srcs6
    }else if(index == 1){
      this.srcs2 = srcs7
    }else if(index == 2){
      this.srcs2 = srcs8
    }else if(index == 3){
      this.srcs2 = srcs9
    }else {
      this.srcs2 = srcs10
    }
  }
  },
};
</script>

<style>

.topTaxi{
   background-image: url('./static/bannerTaxi.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 35vw;
        min-height: 500px;
        color: #fff;
        display: flex;
        flex-flow: column;
     justify-content: center;
        padding-left: 12.5%;
}
        h3,h5{
          text-align: left;
        }
        .driver{
           list-style: none;
        }
        .driver li{
           height: 104px;
           display: flex;
           flex-flow: column;
          justify-content: center;
          padding-left: 38px;
        }
       
         .driver2 li{
           height: 104px;
           display: flex;
           flex-flow: column;
          justify-content: center;
          padding-right: 38px;
        }
      .active{
        border-left: 8px solid #18C47C;
      }
       .active2{
        border-left: 8px solid #ECEDF1;
        opacity: 0.5;
      }
      .right{
        border-right: 8px solid #18C47C;
      }
       .right2{
        border-right: 8px solid #ECEDF1;
        opacity: 0.5;
      }
</style>