<template>
  <div>
    <div class="four2">
      <h1 style="color: #fff; text-align: left;font-size:48px;">120天</h1>
      <h1 style="color: #fff; text-align: left;font-size:48px;">帮你快速拿到网约车牌照</h1>
      <p style="width: 478px; margin-top: 20px; text-align: left">
        120天帮你快速拿到《网络预约出租汽车经营许可证》，50+城市对接成功经验，成熟Saas系统、熟悉全部申请流程、速度快。
      </p>
    </div>
    <div
      style="
        display: flex;
        flex-flow: column;
        align-items: center;
        background: #F2F8F5;
        padding:0 12.5% 150px;
      "
    >
      <div
        style="
          width: 100%;
          height: 112px;
          background: #ffffff;
          box-shadow: 0px 2px 16px 0px rgba(212, 212, 212, 0.5);
          display: flex;
          align-items: center;
          margin-top: -56px;
        "
      >
        <div
          style="
            flex: 1; text-align: center;font-size: 24px; ">
         <router-link to="/solvePlat/" style="text-decoration: none;color:#000;">网约车平台</router-link>
        </div>
        <div style="flex: 1;text-align: center;font-size: 24px; background: #18c47c; height: 112px;line-height: 112px;"><router-link to="/solveLicense/" style="text-decoration: none;color:#fff;">网约车牌照</router-link></div>
      </div>
      <h1 style="margin: 80px 0 32px">网约车牌照申请服务</h1>
      <div style="width: 60px; height: 4px; background: #18c47c;"></div>
      <div style="display:flex;justify-content: space-between;width: 100%;align-items: center;">
          <div style="display:flex;flex-flow: column;">
            <div style="display:flex;margin-top:75px;" v-for="item,index in lis" :key="index">
                    <h1 style="min-width: 48px;height: 48px;background: #18C47C;border-radius:50%;color:#fff;text-align: center;margin-right:16px;">{{item.number}}</h1>
                    <h3 style="max-width: 312px;margin-right:56px;">{{item.content}}</h3>
            </div>
            </div>
            <div style="display:flex;flex-flow: column;">
            <div style="display:flex;margin-top:75px;" v-for="item,index in lis1" :key="index">
                    <h1 style="min-width: 48px;height: 48px;background: #18C47C;border-radius:50%;color:#fff;text-align: center;margin-right:16px;">{{item.number}}</h1>
                    <h3 style="max-width: 312px;margin-right:56px;">{{item.content}}</h3>
            </div>
            </div>
            <div style="max-width: 549px;max-height: 380px;">
            
            <img src="./static/permit.png" alt="" style="width: 100%;height: 100%;">
                
            </div>
      </div>
    </div>
    <div style="height:887px; display: flex;flex-flow: column;align-items: center;padding:0 12.5%;">
        <h1 style="margin: 80px 0 32px">三级等保配合</h1>
      <div style="width: 60px; height: 4px; background: #18c47c"></div>
      <div style=" display: flex;margin-top:112px;width:100%;justify-content: space-between;">
          <div style="max-width: 549px;max-height: 380px;margin-right:75px;">
          <img src="./static/permit2.png" alt="" style="width: 100%;height: 100%;">
          </div>
          <div>
              <div v-for="item,index in lis2" :key="index" style=" display: flex;margin-bottom:40px;">
                  <div style="width: 16px;height: 16px;background: #18C47C;border-radius:50%;margin:9px 16px 0 0;"></div>
                  <div>
                      <h2>{{item.title}}</h2>
                      <h5>{{item.content}}</h5>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div style="height: 868px;background: linear-gradient(180deg, #05C1B3 0%, #18C47C 100%);border: 1px solid #979797;  display: flex;flex-flow: column;align-items: center; padding:0 12.5%;">
         <h1 style="margin: 80px 0 32px;color:#fff;;font-size:36px;">交通部监管平台对接服务内容</h1>
      <div style="width: 60px; height: 4px; background: #fff;"></div>
      <h4 style="margin-top: 24px;color:#fff;">对接交通部信息中心网约车监管平台，调通以下对接接口，并协助获得“数据库已接入交通部监管平台”证明材料</h4>
      <div v-for="item,index in lis3" :key="index" style="display: flex;margin-top:80px;justify-content: space-between;width:100%;">
          <p v-for="i,index in lis3[index].p" :key="index" style="width: 200px;height: 64px;background: #FFFFFF;border-radius: 41px;color:#18C47C;
line-height: 64px;text-align:center;">{{i.text}}</p>
      </div>
    </div>
    <div style="height: 934px;background: #F2F8F5;display: flex;flex-flow: column;align-items: center;  padding:0 12.5%;">
         <h1 style="margin: 80px 0 32px;">网络安全防护配合</h1>
      <div style="width: 60px; height: 4px; background: #18C47C"></div>
      <h4 style="margin-top: 24px;opacity:0.55;">配合通信部门认可的第三方安全检测机构（国家计算机网络与信息安全管理中心省级分中心），并在第三方整改机构，整改系统通过《网约车互联网平台网络安全防护检测报告》，报告包括</h4>
      <div style="display: flex;margin-top: 56px">
          <div style="width: 552px;height: 600px;background: #FFFFFF;box-shadow: 0px 0px 16px 0px #C4DED1;padding:0 24px;display: flex;flex-flow: column;margin-right: 64px">
              <img src="./static/report.png" alt="" style="width: 80px;height: 80px;background: #18C47C;border-radius:50%;margin:32px auto 0;">
              <h2 style="margin-top:24px;text-align:center;">网络安全定级报告内容</h2>
              <div style="width: 504px;height: 2px;background: #E3F7ED;margin-top:24px;"></div>
              <div v-for="item,index in lis4" :key="index" style="display: flex;margin-top:40px;">
                  <img src="./static/correct.png" alt="" style="width: 16px;height: 16px;margin:4px 9px 0 0;line-height:16px;">
                  <div>{{item.content}}</div>
              </div>
          </div>
          <div style="width: 552px;height: 600px;background: #FFFFFF;box-shadow: 0px 0px 16px 0px #C4DED1;padding:0 24px;display: flex;flex-flow: column;">
              <img src="./static/report2.png" alt="" style="width: 80px;height: 80px;background: #18C47C;border-radius:50%;margin:32px auto 0;">
              <h2 style="margin-top:24px;text-align:center;">网络安全符合性评测报告</h2>
              <div style="width: 504px;height: 2px;background: #E3F7ED;margin-top:24px;"></div>
              <div style="display:flex;margin-top:40px;align-items: center;">
              <div v-for="item,index in lis5" :key="index" style="margin-left:59px;">
                  <p style="font-weight: 400;font-size: 16px;">{{item.topic}}</p>
                  <div v-for="i,index in lis5[index].img" :key="index" style="display: flex;margin-top:16px;">
                        <img src="./static/correct.png" alt="" style="width: 16px;height: 16px;margin:4px 9px 0 0;line-height:16px;">
                  <div>{{i.content}}</div>
                  </div>
              </div>
              </div>
          </div>
      </div>
    </div>
    <div style="height:1084px;display: flex;flex-flow: column;padding:0 12.5%;">
         <h1 style="margin-top: 80px;text-align:center;">配合提供网约车牌照申请材料</h1>
      <div style="width: 60px; height: 4px; background: #18c47c;margin:32px auto 56px;"></div>
      <a-tabs :default-active-key="lis6[0].key" @change="callback"  >
         <a-tab-pane :key="item.key" v-for="item in lis6" >
           <span slot="tab">
               <img :src="item.img" alt=""  :style="{ 'width': item.width }">
               <p style="text-align:center;margin-top:11px;">{{item.name}}</p>
           </span>
            <!-- Content of Tab Pane 1 -->
            <div v-for="item,index in lis7" :key="index" style="display:flex;align-items: center;margin:42px 0 92px;">
                 <div v-for="i,index in lis7[index].title" :key="index" style="display:flex;align-items: center;flex: 1;">
                     <img src="./static/word2x.png" alt="" style="width: 84px;height: 84px;margin-right:32px;" >
                     <div>
                         <h5>{{i.content}}</h5>
                         <h5>{{i.content1}}</h5>
                         <h5>{{i.content2}}</h5>
                     </div>
                 </div>
            </div>
         </a-tab-pane>
    </a-tabs>
    </div>
    <div style="height: 934px;background: #F2F8F5;padding-top:83px;">
                 <h1 style="text-align:center;">网约车牌照申请材料(线下材料)</h1>
      <div style="width: 60px; height: 4px; background: #18c47c;margin:32px auto 124px;"></div>
   <div style="display: flex;padding:0 12.5%;">
       <div style="width: 344px;height: 600px;background: #FFFFFF;box-shadow: 0px 0px 16px 0px #C4DED1;padding:0 17px;margin-right:22px;" v-for="item,index in lis8" :key="index">
           <h1 style="width: 120px;height: 120px;background: #18C47C;color:#fff;border-radius:50%;text-align:center;line-height:120px;font-size:60px;margin: -60px auto 32px;">{{item.number}}</h1>
           <div v-for="i,index in lis8[index].img" :key="index" style="display: flex;margin-top:16px;">
                        <img src="./static/correct.png" alt="" style="width: 16px;height: 16px;margin:4px 9px 0 0;line-height:16px;">
                  <div>{{i.content}}</div>
                  </div>
       </div>
   </div>
    </div>
  </div>
</template>

<script>
import img from './static/position.png'
import img1 from './static/position(1).png'
import img2 from './static/position(2).png'
import img3 from './static/position(3).png'
import img4 from './static/position(4).png'
export default {
  data() {
    return {
        lis:[{
            number:'1',
            content:'具备互联网平台和信息数据交互以及处理能力的证明材料',
        },{
            number:'3',
            content:'数据库接入情况',
        },{
            number:'5',
            content:'网络安全管理制度和安全保护技术措施文本',
        },{
            number:'7',
            content:'经营管理制度、安全生产管理制度和服务质量保障制度文本',
        }],
         lis1:[{
            number:'2',
            content:'具备相关监管部门依法调取查询相关网络数据信息条件的证明材料'
        },{
            number:'4',
            content:'服务器设置在中国内地的情况说明'
        },{
            number:'6',
            content:'提供支付结算服务的银行或者非银行支付机构签订的协议文本'
        },{
            number:'8',
            content:'法律法规要求提供的其他资料'
        }],
        lis2:[{
            title:'物理层面安全要求',
            content:'主要是从外界环境、基础设施、运行硬件、介质等方面为信息系统的安全运行提供基本的后台支持和保证'
        },{
            title:'网络层面安全要求',
            content:'为信息系统能够在安全的网络环境中运行提供支持，确保网络系统安全运行，提供有效的网络服务'
        },{
            title:'主机层面安全要求',
            content:'在物理、网络层面安全的情况下，提供安全的操作系统和安全的数据库管理系统，以实现操作系统和数据库管理系统的安全运行'
        },{
            title:' 应用层面安全要求',
            content:'在物理、网络、系统等层面安全的支持下，实现用户安全需求所确定的安全目标'
        },{
            title:'数据及备份恢复层面安全要求',
            content:'全面关注信息系统中存储、传输、处理等过程的数据的安全性'
        }],
        lis3:[{
            p:[{
                text:'公司基本信息'
            },{
                text:'运营规模'
            },{
                text:'支付信息'
            },{
                text:'服务机构信息'
            },{
                text:'经营许可信息'
            },]
        },{
            p:[{
                text:'运价信息'
            },{
                text:'车辆基本信息'
            },{
                text:'车辆保险信息'
            },{
                text:'网约车车辆里程信息'
            },{
                text:'驾驶员培训信息'
            },]
        },{
            p:[{
                text:'移动终端信息'
            },{
                text:'统计信息'
            },{
                text:'乘客基本信息'
            },{
                text:'订单发起/成功/撤销信息'
            },{
                text:'车辆上线/下线/出发/到达/支付信息'
            },]
        },{
            p:[{
                text:'驾驶员定位信息'
            },{
                text:'车辆定位信息'
            },{
                text:'乘客评价/投诉信息'
            },{
                text:'驾驶员处罚/信誉信息'
            },{
                text:'文件接口'
            },]
        }],
        lis4:[{
            content:'企业特征、业务范围、安全管理基本情况以及其他基本情况'
        },{
            content:'安全技术情况，包括网络与系统所在 的物理环境、网络拓扑结构、网络关键设备（包 括服务器、安全设备、应用系统等）情况、服务范围、网络处理和传送的信息资产、服务范围和用户类型等信息，网络边界'
        },{
            content:'明网络安全等级定级理由、要素以及安全等级确定结果等。'
        }],
        lis5:[{
            topic:'A、网络安全符合性评测',
            img:[{
                content:'系统安全加固'
            },{
                content:'网络拓扑'
            },{
                content:'冗余与灾难备份'
            },{
                content:'网络及设备安全策略'
            },{
                content:'设备漏洞'
            },{
                content:'口令安全'
            },{
                content:'介质管理'
            },{
                content:'日志'
            }]
        },{
            topic:'B、技术检测对象',
            img:[{
                content:'生产主机安全'
            },{
                content:'账号安全'
            },{
                content:'口令安全'
            },{
                content:'授权安全'
            },{
                content:'Web安全'
            },{
                content:'补丁安全'
            },{
                content:'客户信息安全'
            },{
                content:'开放端口安全'
            }]
        }],
        lis6:[{
            key:1,
            name:'省级通讯管理局',
            img:img,
            width:'278px'
        },{
            key:2,
            name:'省级公安厅',
            img:img1,
            width:'155px'
        },{
            key:3,
            name:'省国家地税局',
            img:img2,
            width:'166px'
        },{
            key:4,
            name:'人民银行省级中心分行',
            img:img3,
            width:'163px'
        },{
            key:5,
            name:'网络安全和信息化办公室',
            img:img4,
            width:'278px'
        }],
        lis7:[],
        lis9:[{
           title:[{
               content:'1、网络预约出租汽车经营申请表.docx',
               content1:'2、企业法人营业执照.docx',
               content2:'3、经营管理制度.docx',
           },{
               content:'4、安全生产管理制度.docx',
               content1:'5、承诺书.docx',
               content2:'6、公司基本信息.docx',
           },{
               content:'7、负责人身份.docx',
               content1:'8、企业法人营业执照.docx',
               content2:'9、技术部门及人员信息.docx',
           }]
        },{
           title:[{
               content:'10、技术研发和维护部门架构及人员证明材料.docx',
               content1:'11、服务器.docx',
           },{
               content:'12、服务器托管协议或互联网接入协议.docx',
               content1:'13、线上服务功能说明.docx',
           },{
               content:'14、面向乘客和驾驶员APP的功能设计和说明.docx',
               content1:'15、平台软硬件及数据库说明.docx',
           }]
        },{
           title:[{
               content:'16、配合依法查询、调取相关数据信息的功能设计、工作制度和责任机构.docx',
               content1:'17、系统架构文档.docx',
           },{
               content:'18、面向乘客和驾驶员APP的功能设计和说明.docx',
               content1:'19、网约车网络安全定级备案信息表.docx',
           },{
               content:'20、信息系统安全等级保护备案证明.docx',
               content1:'21、网安全防护评测报告.docx',
               content2:'22、网安整改报告.docx',
           }]
        },{
           title:[{
               content:'23、乘客端APP测评_IOS版_报告',
               content1:'24、司机端APP测评_IOS版_报告',
               content2:'25、乘客端APP评测_安卓版_报告',
           },{
               content:'26、司机端APP评测_安卓版_报告',
               content1:'27、互联网新技术新业务安全评估',
               content2:'27、互联网新技术新业务安全评估',
           },{
               content:'29、其他补充资料：接入交通运输部网约车监管信息情况说明',
               content1:'30、网络与信息安全应急预案',
           }]
        },],
        lis8:[{
             number:'1',
             img:[{
                 content:'投资人、负责人身份、资信证明原件及其复印件'
             },{
                 content:'经办人身份证原件及复印件和委托书企业法人营业执照'
             },{
                 content:'在本市办理注册登记的办公场所、负责人员和管理人员等信息'
             },{
                 content:'在本市办理注册登记的办公场所、负责人员和管理人员等信息'
             },{
                 content:'企业在服务地行政区域内拥有的房产证明或3年以上房屋租赁合同'
             },{
                 content:'企业与管理人员签订的聘用合同'
             }]
        },{
             number:'2',
             img:[{
                 content:'经营管理制度'
             },{
                 content:'安全生产管理制度'
             },{
                 content:'服务质量保障制度文本'
             },{
                 content:'与线下实体运输企业商业合作方式及责任约定'
             },{
                 content:'运价定价原则、标准及动态调整方式'
             },{
                 content:'个人所有的车辆注册及人员管理方式'
             },{
                 content:'签订劳动合同或者协议的相关规定'
             },{
                 content:'驾驶员经营行为奖惩制度'
             },{
                 content:'发票管理制度'
             }]
        },{
             number:'3',
             img:[{
                 content:'注册车辆、人员信息发布制度'
             },{
                 content:'突发事件应急响应预案'
             },{
                 content:'服务质量保障制度文本'
             },{
                 content:'与线下实体运输企业商业合作方式及责任约定'
             },{
                 content:'运价定价原则、标准及动态调整方式'
             },{
                 content:'个人所有的车辆注册及人员管理方式'
             },{
                 content:'签订劳动合同或者协议的相关规定'
             },{
                 content:'驾驶员经营行为奖惩制度'
             },{
                 content:'发票管理制度'
             }]
        },{
             number:'4',
           img:[{
                 content:'注册车辆、人员信息发布制度'
             },{
                 content:'突发事件应急响应预案'
             },{
                 content:'服务质量保障制度文本'
             },{
                 content:'与线下实体运输企业商业合作方式及责任约定'
             },{
                 content:'运价定价原则、标准及动态调整方式'
             },{
                 content:'个人所有的车辆注册及人员管理方式'
             },{
                 content:'签订劳动合同或者协议的相关规定'
             },{
                 content:'驾驶员经营行为奖惩制度'
             },{
                 content:'发票管理制度'
             }]
        }],
        lis10:[{
           title:[{
               content:'1、网络预约出租汽车经营申请表.docx',
               content1:'2、企业法人营业执照.docx',
               content2:'3、经营管理制度.docx',
           },{
               content:'4、安全生产管理制度.docx',
               content1:'5、配合依法查询、调取相关数据信息的功能设计、工作制度和责任机构.docx',
           },{
               content:'6、网络应用、数据等所有服务器物理地址、IP地址、用途分工等情况说明.docx',
               content1:'7、系统安全等级测评报告.docx',
           }]
        },{
           title:[{
               content:'8、专家评审意见.docx',
               content1:'9、信息系统安全等级保护备案证明.docx',
           },{
               content:'10、用户真实身份认证管理制度及隐私数据保护，具体数据库.docx',
               content1:'11、后台用户信息.docx',
           },{
               content:'12、为依法防范.docx',
               content1:'13、网络与信息安全保障制度.docx',
               content2:'14、互联网新技术新业务安全评估.docx',
           }]
        },{
           title:[{
               content:'15、网络安全应急预案.docx',
               content1:'16、信息和数据安全承诺书.docx',
               content2:'17、服务质量承诺书.docx',
           },{
               content:'18、平台是否具备信息发布.docx',
               content1:'19、不发布有害信息承诺书.docx',
               content2:'20、网上内容处置能力证明材料.docx',
           },{
               content:'21、承诺书.docx',
               content1:'22、关于网络安全整改情况说明.docx',
               content2:'23、渗透测试报告.docx',
           }]
        },{
           title:[{
               content:'24、网安全防护评测报告.docx',
               content1:'24、网安全防护评测报告.docx',
               content2:'26、信息系统安全等级备案表.docx',
           }]
        },],
        lis11:[{
           title:[{
               content:'1、网络预约出租汽车经营申请表.docx',
               content1:'2、企业法人营业执照.docx',
               content2:'3、经营管理制度.docx',
           },{
               content:'4、安全生产管理制度.docx',
               content1:'5、配合依法查询、调取相关数据信息的功能设计、工作制度和责任机构.docx',
           }]
        }],
        lis12:[{
           title:[{
               content:'1、网络预约出租汽车经营申请表4.docx',
               content1:'2、企业法人营业执照.docx',
               content2:'3、经营管理制度.docx',
           },{
               content:'4、安全生产管理制度.docx',
               content1:'5、企业与银行或非银行支付机构合作业务模式的详细描述.docx',
           }]
        }],
        lis13:[{
           title:[{
               content:'1、网络预约出租汽车经营申请表4.docx',
               content1:'2、企业法人营业执照.docx',
               content2:'3、经营管理制度.docx',
           },{
               content:'4、安全生产管理制度.docx',
               content1:'5、网上内容处置能力证明材料.docx',
           },{
               content:'6、平台是否具备信息发布.docx',
               content1:'7、不发布有害信息承诺书.docx',
           }]
        }],

    };
  },
  created(){
    this.lis7 = this.lis9
  },
  methods: {
      callback(key) {
      if(key == 1){
        this.lis7 = this.lis9
      }else if(key == 2){
        this.lis7 = this.lis10
      }else if(key == 3){
        this.lis7 = this.lis11
      }else if(key == 4){
        this.lis7 = this.lis12
      }else if(key == 5){
        this.lis7 = this.lis13
      }
    },
  },
};
</script>

<style>
.four2 {
  /* background-image: url("./static/banner2x(1).png"); */
  background-image: url("./static/banner (1).svg");
  background-repeat: no-repeat;
   height: 35vw;
   min-height: 500px;
  background-size: 100%;
  color: #fff;
  padding: 8% 0 0 12.5%;
}
.ant-tabs-nav-scroll{
background: #FFFFFF;
}

</style>