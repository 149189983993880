<template>
  <div id="app">
    <div class="bai"  v-show="!(path=='/helloIndex/')"></div>
     <div v-show="!(path=='/helloIndex/')" class="lead1" >
      <img
        src="@/components/static/logo-22x.png"
        alt=""
        class="bai1"
      />
          <a-menu v-model="current" mode="horizontal" :selectable="true" @select="select" >
      <a-menu-item key="/helloIndex/" ><a  href="/helloIndex/" >首页</a></a-menu-item>
      <a-sub-menu key="productTaxi">
        <span slot="title" class="submenu-title-wrapper" >产品<img src="@/components/static/down.png" alt="" class="bai3" ></span>
        <a-menu-item-group >
          <a-menu-item key="/productTaxi/">
           <router-link to="/productTaxi/" >出租车</router-link>
          </a-menu-item>
          <a-menu-item key="/productCity/">
           <router-link to="/productCity/" >城际拼车</router-link>
          </a-menu-item>
           <a-menu-item key="/productRun/">
          <router-link to="/productRun/" >定制客运</router-link>
          </a-menu-item>
           <a-menu-item key="/productPlane/">
          <router-link to="/productPlane/" >接送机</router-link>
          </a-menu-item>
        </a-menu-item-group>
       
      </a-sub-menu>
      <a-sub-menu key="solvePlat">
        <span slot="title" class="submenu-title-wrapper" >解决方案<img src="@/components/static/down.png" alt="" class="bai4" ></span>
        <a-menu-item-group >
          <a-menu-item key="/solvePlat/">
          <router-link to="/solvePlat/" >网约车平台</router-link>
          </a-menu-item>
          <a-menu-item key="/solveLicense/">
           <router-link to="/solveLicense/" >网约车牌照</router-link>
          </a-menu-item>
        </a-menu-item-group>
       
      </a-sub-menu>
      
      <a-menu-item key="/helloCase/">
        <router-link to="/helloCase/" >案例</router-link>
      </a-menu-item>
      <a-menu-item key="/helloClassic/">
        <router-link to="/helloClassic/" >经典案例</router-link>
      </a-menu-item>
      <!-- <a-menu-item key="/helloNews">
     <router-link to="/helloNews" >新闻动态</router-link>      
      </a-menu-item> -->
      <a-menu-item key="/helloAbout/">
        <router-link to="/helloAbout/" >关于</router-link>
      </a-menu-item>
      <a-menu-item key="/helloContact/">
<router-link  to="/helloContact/"  >联系我们</router-link >      
</a-menu-item>
    </a-menu>

    </div >
         <div v-show="(path=='/helloIndex/')" class="lead">
      <img
        src="@/components/static/LOGO332x.png"
        alt=""
        class="bai1"
       
      />
          <a-menu v-model="current" mode="horizontal" :selectable="true" @select="select" >
      <a-menu-item key="/helloIndex/" class="bai6" ><a href="/"  >首页</a></a-menu-item>
      <a-sub-menu key="productTaxi" >
        <span slot="title" class="submenu-title-wrapper" >产品<img src="@/components/static/down.png" alt="" class="bai3" ></span>
        <a-menu-item-group >
          <a-menu-item key="/productTaxi/">
           <router-link to="/productTaxi/" >出租车</router-link>
          </a-menu-item>
          <a-menu-item key="/productCity/">
           <router-link to="/productCity/" >城际拼车</router-link>
          </a-menu-item>
           <a-menu-item key="/productRun/">
          <router-link to="/productRun/" >定制客运</router-link>
          </a-menu-item>
           <a-menu-item key="/productPlane/">
          <router-link to="/productPlane/" >接送机</router-link>
          </a-menu-item>
        </a-menu-item-group>
       
      </a-sub-menu>
      <a-sub-menu key="solvePlat">
        <span slot="title" class="submenu-title-wrapper" >解决方案<img src="@/components/static/down.png" class="bai4" alt="" ></span>
        <a-menu-item-group >
          <a-menu-item key="/solvePlat/">
          <router-link to="/solvePlat/" >网约车平台</router-link>
          </a-menu-item>
          <a-menu-item key="/solveLicense/">
           <router-link to="/solveLicense/" >网约车牌照</router-link>
          </a-menu-item>
        </a-menu-item-group>
       
      </a-sub-menu>
      
     
      <a-menu-item key="/helloCase/">
        <router-link to="/helloCase/"  style="color:#fff;">案例</router-link>
      </a-menu-item>
      <a-menu-item key="/helloClassic/">
        <router-link to="/helloClassic/"  style="color:#fff;" >经典案例</router-link>
      </a-menu-item>
      <!-- <a-menu-item key="/helloNews">
     <router-link to="/helloNews"   style="color:#fff;">新闻动态</router-link>      
</a-menu-item> -->
      <a-menu-item key="/helloAbout/">
        <router-link to="/helloAbout/"  style="color:#fff;">关于</router-link>
      </a-menu-item>
      <a-menu-item key="/helloContact/">
<router-link  to="/helloContact/"    style="color:#fff;" >联系我们</router-link >      
</a-menu-item>
    </a-menu>

      <!-- <div>
        <ul
          style="
            display: flex;
            color: #000;
            list-style-type: none;
            padding: 0;
            margin: 0 240px 0 0;
            height: 82px;
          "
        >
          <li class="top">
            <a href="./" style="text-decoration: none; color: #000"
              >首页</a
            >
          </li>
          <li class="top1">
            产品<img src="@/components/static/down2.png" alt="" style="width:12px;height:12px;margin-left:7px;">
            <ul>
               <li>
                          <router-link to="/productTaxi" style="text-decoration: none;">出租车</router-link> -->
                          <!-- <a href="./taxi.vue" style="text-decoration: none;">出租车</a> -->
                          <!-- </li>
                        <li><router-link to="/productCity" style="text-decoration: none;">城际</router-link></li>
                        <li><router-link to="./productRun" style="text-decoration: none;">同城跑腿</router-link></li>
                        <li><router-link to="./productPlane" style="text-decoration: none;">接送机</router-link></li>      
            </ul>
          </li> -->
          <!-- <li class="top2">
            解决方案<img src="@/components/static/down2.png" alt="" style="width:12px;height:12px;margin-left:7px;">
            <ul>
              <li><router-link to="/solvePlat" style="text-decoration: none;color:#000;">网约车平台</router-link></li>
              <li><router-link to="/solveLicense" style="text-decoration: none;color:#000;">网约车牌照</router-link></li>
            </ul>
          </li>
          <li class="top"><router-link to="/helloCase" style="text-decoration: none;color:#000;">案例</router-link></li>
          <li class="top"><router-link to="/helloClassic" style="text-decoration: none;color:#000;">经典案例</router-link></li>
          <li class="top"><router-link to="/helloNews" style="text-decoration: none;color:#000;">新闻动态</router-link></li>
          <li class="top"><router-link to="/helloAbout" style="text-decoration: none;color:#000;">关于</router-link></li>
          <li class="top"><router-link
              to="/helloContact"
              style="text-decoration: none; color: #000"
              >联系我们</router-link ></li>
        </ul>
      </div> -->
    </div>

    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view></router-view>
    <div class="bai9" >
        <div class="bai10">
            <img src="@/components/static/logo-22x.png" alt="" class="bai11" >
            <div class="bai12" >
                <p class="bai13" >服务热线：</p>
                <div>
                    <div class="bai14" >
                        <img src="@/components/static/landline.svg" alt="" class="bai15" >
                        <div class="bai13"> 0532-88180018</div>
                    </div>
                    <div class="bai16" >
                        <img src="@/components/static/phone-fill.svg" alt="" class="bai15" >
                        <div class="bai13">15954211165</div>
                    </div>
                </div>

            </div>
        </div>
        <div class="bai17">
            <div class="bai18" >
                <ul class="bottom">
                    <li >产品</li>
                    <li><router-link class="dong" to="/productTaxi/" >出租车</router-link> </li>
                    <li><router-link class="dong" to="/productCity/" >城际拼车</router-link></li>
                    <li><router-link class="dong" to="/productRun/" >定制客运</router-link></li>
                    <li><router-link class="dong" to="/productPlane/" >接送机</router-link></li>      
                </ul>
                <ul class="bottom">
                    <li >解决方案</li>
              <li><router-link class="dong" to="/solvePlat/" >网约车平台</router-link></li>
              <li><router-link class="dong" to="/solveLicense/" >网约车牌照</router-link></li>
                </ul>
                <ul class="bottom">
                    <li >经典案例</li>
                   <li><router-link class="dong" to="/helloClassic/" >东营出行</router-link></li>
                </ul>
                <ul class="bottom">
                    <li >关于我们</li>
                    <li ><router-link class="dong" to="/helloAbout/" >公司介绍</router-link></li>
                    <!-- <li>公司介绍</li> -->
                </ul>
            </div>
            <div class="bai19" >
                <div class="phone">
                    <img class="saleImg" src="@/components/static/QQ.png" alt="" >
                    <p class="sale" >售前396362666</p>
                </div>
                <div class="phone">
                    <img class="saleImg" src="@/components/static/QQ.png" alt="" >
                    <p class="sale" >售后2788952912</p>
                </div>
                <div class="phone">
                    <img class="saleImg1" src="@/components/static/keyou.jpg" alt="" >
                    <p class="sale" >微信keyoubainuo</p>
                </div>
            </div>
        </div>
        <div class="bai20">
            <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #000000;pacity: 0.35;">©http://www.bainuodianzhao.com 鲁ICP备15015927号-2 </a>
            <div style="margin-top: 24px;">地址：山东省青岛市黄岛区香江路713号内1栋办公楼138室 联系邮箱：keubano@foxmail.com</div>
        </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloIndex.vue'


export default {
  
  data() {
    return {
      current: [],
      path:''
    };
  },
   created(){
    
    // if(window.sessionStorage.getItem("list") == null){
    //   this.current[0] = '/helloIndex/'
    // }else{
    //   this.current[0] =  window.sessionStorage.getItem("list")
    // }
  },
  mounted(){
     console.log("路由",this.$route.path)
    console.log("刷新mounted",this.current)
    this.path = this.$route.path
    this.current[0] = this.$route.path
  },
  watch:{
     $route(to){
       this.path = to.path
       this.current[0] = to.path
     }
  },
  methods:{
    select(key){
      console.log("select",key.key)
      this.current[0] = key.key
      // this.current[0] = key
    //  localStorage.setItem('zheng', key);
    window.sessionStorage.setItem("list",key.key)
    }
  }
  // name: 'App',
  // components: {
  //   HelloWorld
  // }
}
</script>

<style>
.bai{
  height: 82px;  
  width: 100%;
}
.bai1{
  /* width: 90px;  */
  height: 24px; 
}
.bai3{
  width:12px;
  height:12px;
  margin-left:4px;
}
.bai4{
  width:12px;
  height:12px;
  margin-left:4px;
}

.bai6{
 border-bottom: 2px solid #18C47C;
}

.bai9{
margin:0 12.5%;
}
.bai10{
  display: flex;
  height: 170px;
  justify-content: space-between;
  border-bottom: 1px solid #EEEEEE;
  padding-top:48px;
}
.bai11{
  width: 169px;
  height:48px;
}
.bai12{
 display: flex;
 font-weight:bold;
}
.bai13{
  font-size:24px;
}
.bai14{
  display: flex;
  align-items: center;
  justify-content: center;
}
.bai15{
  margin-left: 10px;
  width:24px;
  height:24px;
}
.bai16{
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.bai17{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 321px;
    border-bottom: 1px solid #EEEEEE;
}
.bai18{
 display:flex;
 justify-content: center;
 flex:1;
}
.bai19{
  display: flex;
  flex:1;
  justify-content: flex-end;
}
.bai20{
  height:183px;
  padding-top: 24px;
  color: #000000;
  opacity: 0.35;
  font-size: 16px;
}
.firstTab{
  color:#18C47C;
}
#app {
  overflow-x:hidden;
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
  /* margin-top: 60px; */
}
 .ant-menu-horizontal > .ant-menu-submenu-selected{
          color: #18C47C;
          border-bottom: 2px solid #18C47C;

        }
        .ant-menu-item-selected {
          color: #18C47C !important;
          border-bottom: 2px solid #18C47C !important;
}
        .ant-menu-item-active {
  border-bottom: 2px solid #18C47C !important;
  color: #18C47C !important;
}
.ant-menu-item-selected > a, .ant-menu-item-selected > a:hover{
  color: #18C47C !important;
}
.ant-menu-submenu-active {
  border-bottom: 2px solid #18C47C !important;
  color: #18C47C !important;
}
.ant-menu-submenu-title:hover{
  color: #18C47C !important;
}
.ant-menu-submenu:hover{
  color: #18C47C !important;
  /* border-bottom: 2px solid #18C47C !important; */
}
.ant-menu-item a:hover {
  color: #18C47C !important;
}
.ant-menu-horizontal > .ant-menu-item-selected > a{
  color: #18C47C !important;
}
.ant-menu-item , .ant-menu-submenu-title{
  padding:0 30px;
}
.ant-menu-vertical.ant-menu-sub, .ant-menu-vertical-left.ant-menu-sub, .ant-menu-vertical-right.ant-menu-sub{
  min-width: 100px;
}
.top1 ul {
  display: none;
  list-style: none;
  background-color: #fff;
  width: 100px;
}

.top1 ul > li {
  height: 50px;
  line-height: 50px;
  color: #000000;
   margin-left: -40px;
}
.top2 ul {
  display: none;
  list-style: none;
  background-color: #fff;
  width: 100px;
}

.top2 ul > li {
  height: 50px;
  line-height: 50px;
  color: #000000;
   margin-left: -40px;
}
.top {
  list-style: none;
  /* margin-left: 60px; */
  width: 100px;
  text-align: center;
  line-height: 82px;
  height: 82px;
  margin-top: 4px;
}

.top1 {
  list-style: none;
  /* margin-left: 60px; */
  float: left;
  width: 100px;
  text-align: center;
  /*以下设置仅为方便查看效果*/

  height: 82px;
  /* border: 1px solid black; */
  text-align: center;
  line-height: 82px;
  margin-top: 4px;
}
.top2 {
  list-style: none;
  /* margin-left: 60px; */
  float: left;
  width: 100px;
  text-align: center;
  /*以下设置仅为方便查看效果*/

  height: 82px;
  /* border: 1px solid black; */
  text-align: center;
  line-height: 82px;
  margin-top: 4px;
}
.top1:hover ul {
  display: block;
}

.top1:hover ul li a {
  color: #000;
}

.top1:hover ul li:hover a {
  color: #18c47c;
}
.top2:hover ul {
  display: block;
}

.top2:hover ul li a {
  color: #000;
}

.top2:hover ul li:hover a {
  color: #18c47c;
}
.dong{
  color: #000;
}
.dong:hover{
color: #18c47c;
}
 .bottom {
            list-style-type: none;
            padding: 0;
            flex: 1;
            /* margin: 0 120px 0 0; */

        }

        .phone {
            margin-left: 60px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
        }

       .bottom li:first-child {
            color: #000000;
            font-size: 18px;
            font-weight: bold;
        }

        .bottom li:nth-child(n+2) {
            color: #000000;
            opacity: 0.35;
            font-size: 16px;
        }

        .bottom li {
            margin-bottom: 24px;

        }
        .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color:red;
}
.ant-menu-horizontal{
  border-bottom: none;
}
.lead1{
        height: 82px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:0 12.5%;
         position:fixed;
        top:0;
        z-index:10; 
        background:#fff;

}
.lead{
 height: 82px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:0 12.5%;
        position:fixed;
        top:0;
        z-index:10;
          background-color: rgba(14, 16, 18, 0.75) ;
}
.lead .ant-menu{
  background:none;
  color: #fff;
}
.lead .ant-menu-horizontal > .ant-menu-submenu-selected{
          color: #18C47C;
          border-bottom: 2px solid #18C47C;

        }
  .sale{
font-weight:bold;
margin-top: 28px;
  }
  .saleImg{
width: 60px;
height: 60px;
margin:20px 0;
  }
  .saleImg1{
width: 102px;
height: 100px;
  }
</style>
