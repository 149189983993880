<template>
  <div>
    <div class="four">
      <h1 style="color: #fff; text-align: left;font-size:48px;">15天</h1>
      <h1 style="color: #fff; text-align: left;font-size:48px;">快速搭建出行系统平台</h1>
      <p style="width: 478px; margin-top: 20px; text-align: left;text-align: justify;">
        提供「从端到端」一体化的城际业务解决方案，支持在线H5、面对面扫码等多种下单方式，以及基于线路的管理、运营能力，帮您解决从低成本获客到精细化运营中的各类问题。
      </p>
    </div>
    <div
      style="
        height: 934px;
        display: flex;
        flex-flow: column;
        align-items: center;
        padding:0 12.5%;
        background: #f4f8ff;
      "
    >
      <div
        style="
          width: 100%;
          height: 112px;
          background: #ffffff;
          box-shadow: 0px 2px 16px 0px rgba(212, 212, 212, 0.5);
          display: flex;
          align-items: center;
          margin-top: -56px;
        "
      >
        <div
          style="
            flex: 1;
            background: #18c47c;
            height: 112px;
            line-height: 112px;
            text-align: center;
            font-size: 24px;
          "
        >
         <router-link to="/solvePlat/" style="text-decoration: none;color:#fff;">网约车平台</router-link>
        </div>
        <div style="flex: 1; text-align: center; font-size: 24px">
          <router-link to="/solveLicense/" style="text-decoration: none;color:#000;">网约车牌照</router-link>
        </div>
      </div>
      <h1 style="margin: 80px 0 32px">产品矩阵</h1>
      <div style="width: 60px; height: 4px; background: #18c47c"></div>
      <div
        v-for="(item, index) in lis"
        :key="index"
        style="display: flex; margin-top: 80px;width:100%;"
      >
        <div
          style="
            flex:1;
            height: 208px;
            background: #ffffff;
            margin-right: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <img
            :src="item.img1"
            alt=""
            style="
              width: 104px;
              height: 104px;
              border-radius: 8px;
              margin-right: 40px;
            "
          />
          <h2>{{ item.title }}</h2>
        </div>
        <div
          style="
            flex:1;
            height: 208px;
            background: #ffffff;
            margin-right: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <img
            :src="item.img2"
            alt=""
            style="
              width: 104px;
              height: 104px;
              border-radius: 8px;
              margin-right: 40px;
            "
          />
          <h2>{{ item.title2 }}</h2>
        </div>
        <div
          style="
            flex:1;
            height: 208px;
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <img
            :src="item.img3"
            alt=""
            style="
              width: 104px;
              height: 104px;
              border-radius: 8px;
              margin-right: 40px;
            "
          />
          <h2>{{ item.title3 }}</h2>
        </div>
      </div>
    </div>
    <div
      style="
        height: 1320px;
        background: #1a2c4d;
        padding: 80px 12.5%;
        display: flex;
        align-items: center;
        flex-flow: column;
      "
    >
      <h1 style="color: #fff">合作伙伴</h1>
      <div style="width: 60px; height: 4px; background: #18c47c;margin-top:32px;"></div>
      <div v-for="(item, index) in lis2" :key="index" style="display:flex;align-item:center;justify-content:space-between;width:100%;">
        <img
          :src="i.src"
          alt=""
          v-for="(i, index) in lis2[index].img"
          :key="index"
          style="width: 184px; height: 184px;border-radius:40px;margin-top:80px;"
        />
      </div>
    </div>
    <div
      style="
        height: 742px;
        padding: 136px 12.5% 0;
        display: flex;
        align-items: center;
        flex-flow: column;
      "
    >
      <h1 style="font-size: 36px">多样化流量入口</h1>
      <div
        style="width: 60px; height: 4px; background: #18c47c; margin-top: 36px"
      ></div>
      <div style="display: flex; margin-top: 144px;width: 100%;justify-content: space-between;">
        <div class="midImg1"><img class="midImg" src="./static/android.png" alt="" /></div>
         <div class="midImg1"><img class="midImg" src="./static/IOS2x.png" alt="" /></div>
         <div class="midImg1"><img class="midImg" src="./static/official.png" alt="" /></div>
         <div class="midImg1"><img class="midImg" src="./static/account.png" alt="" /></div>
         <div class="midImg1"><img class="midImg" src="./static/uniapp.png" alt="" /></div>
      </div>
    </div>
    <div
      style="
        height: 870px;
        background: #0f1a30;
        display: flex;
        align-items: center;
        flex-flow: column;
        padding: 80px 12.5% 0;
      "
    >
      <h1 style="font-size: 36px; color: #fff">同时内置多种场景</h1>
      <div style="width: 60px; height: 4px; background: #18c47c;margin-top:32px;"></div>
      <div style="display: flex; margin-top: 120px">
        <div class="second"  v-for="(item, index) in lis3" :key="index" style="width: 288px; height:560px;overflow:hidden;">
        <div
          class="first"
          :style="{'background-image': 'url(' + item.PlaceImgUrl + ')','background-repeat': 'no-repeat', 'background-size': 'cover',
            width: '288px', height: '560px', display: 'flex', 'flex-flow': 'column',  'align-items': 'center', 'justify-content': 'center', 
  'transition': 'all 0.5s', }"
        >
          <img :src="item.icon" alt="" style="width: 72px; height: 72px" />
          <h1 style="font-size: 36px; color: #fff; margin-top: 40px">
            {{ item.title }}
          </h1>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "./static/fast.png";
import img2 from "./static/project.png";
import img3 from "./static/cityCar.png";
import img4 from "./static/leg.png";
import img5 from "./static/texi222.png";
import img6 from "./static/goBy.png";
import first1 from "./static/edit.png";
import first2 from "./static/edit5.png";
import first3 from "./static/edit2.png";
import first4 from "./static/edit3.png";
import first5 from "./static/edit4.png";
import icon1 from "./static/user2x.png";
import icon2 from "./static/public.png";
import icon3 from "./static/company.png";
import icon4 from "./static/travel.png";
import icon5 from "./static/kid.png";
import logo1 from './static/logo (2).jpg'
import logo2 from './static/logo (4).png'
import logo3 from './static/logo (5).png'
import logo4 from './static/logo (6).png'
import logo5 from './static/logo (7).png'
import logo6 from './static/logo (8).png'
import logo7 from './static/logo (9).png'
import logo8 from './static/logo (10).png'
import logo9 from './static/logo (11).png'
import logo10 from './static/logo (13).png'
import logo11 from './static/logo (14).png'
import logo12 from './static/52.jpg'
import logo13 from './static/logo (16).jpg'
import logo14 from './static/logo (18).jpg'
import logo15 from './static/logo (6).jpg'
import logo16 from './static/logo (21).jpg'
import logo17 from './static/logo (22).jpg'
import logo18 from './static/logo (23).jpg'
import logo19 from './static/logo (24).jpg'
import logo20 from './static/logo (25).jpg'

export default {
  data() {
    return {
      lis: [
        {
          img1: img1,
          img2: img2,
          img3: img3,
          title: "快车",
          title2: "专车",
          title3: "城际拼车",
        },
        {
          img1: img4,
          img2: img5,
          img3: img6,
          title: "跑腿",
          title2: "出租车",
          title3: "顺风车",
        },
      ],
      lis2: [
        {
          img: [
            {
              src: logo1,
            },
            {
              src: logo2,
            },
            {
              src: logo3,
            },
            {
              src: logo4,
            },
            {
              src: logo5,
            },
          ],
        },
        {
          img: [
            {
              src: logo6,
            },
            {
              src: logo7,
            },
            {
              src: logo8,
            },
            {
              src: logo9,
            },
            {
              src: logo10,
            },
          ],
        },
        {
          img: [
            {
              src: logo11,
            },
            {
              src: logo12,
            },
            {
              src: logo13,
            },
            {
              src: logo14,
            },
            {
              src: logo15,
            },
          ],
        },
        {
          img: [
            {
              src: logo16,
            },
            {
              src: logo17,
            },
            {
              src: logo18,
            },
            {
              src: logo19,
            },
            {
              src: logo20,
            },
          ],
        },
      ],
      lis3: [
        {
          PlaceImgUrl: first1,
          icon: icon1,
          title: "普通用户",
        },
        {
          PlaceImgUrl: first2,
          icon: icon2,
          title: "公务用车",
        },
        {
          PlaceImgUrl: first3,
          icon: icon3,
          title: "商务用车",
        },
        {
          PlaceImgUrl: first4,
          icon: icon4,
          title: "旅游包车",
        },
        {
          PlaceImgUrl: first5,
          icon: icon5,
          title: "母婴专车",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style>
.four {
  /* background-image: url("./static/banner2x.png"); */
  background-image: url("./static/banner.svg");
  background-repeat: no-repeat;
  height: 35vw;
  min-height: 500px;
  background-size: 100%;
  color: #fff;
  padding: 8% 0 0 12.5%;
}
.midImg {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.midImg1 {
  max-width: 240px;
  max-height: 240px;
  border-radius: 20px;
  margin-right: 15px;
}
.first:hover{
  transform: scale(1.5);
}
</style>