// import Vue from 'vue'
// import App from './App.vue'

// Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
import Vue from 'vue';
import Antd from 'ant-design-vue';
import router from './router';
// import Button from 'ant-design-vue/lib/button';
import 'ant-design-vue/dist/antd.css';
import App from './App';
// Vue.component(Button.name, Button);
Vue.use(Antd);
Vue.config.productionTip = false;
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
  
});
router.beforeEach((to, form, next) => {
  if (to.name) {
      document.title = to.name
  } else {
      document.title = '佰诺出行-青岛科优佰诺' //此处写默认的title
  }
  next()
});
new Vue({
  render: h => h(App),
  router,
  mounted () {
    // 触发renderAfterDocumentEvent
    document.dispatchEvent(new Event('render-event'))
    // document.dispatchEvent(new Event('custom-render-trigger'))
  }
}).$mount('#app');